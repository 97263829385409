import React, { useCallback, useEffect, useRef, useState } from "react";
import { BackArrowIcon, CameraIcon, CloseIcon } from "../common/Icons";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import dummy_img from "../../assets/images/jpeg/bg-image.jpg";
import { toast } from "react-toastify";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import _ from "lodash"; // Import lodash for debounce

function EditProfilePopup({ setEditProfilePopup, initialData }) {
  const { editProfile, checkUsernameAvailability } = UseProfileContext();
  const [imageUrl, setImageUrl] = useState(initialData?.profileImage || null);
  const [secondUrl, setSecondUrl] = useState(initialData?.coverImage || null);
  const [name, setName] = useState(initialData?.fullName || "");
  const [user, setUser] = useState(initialData?.userName || "");
  const [title, setTitle] = useState(initialData?.designation || "");
  const [description, setDescription] = useState(initialData?.bio || "");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [cropPopup, setCropPopup] = useState(false);
  const cropperRef = useRef(null);

  const handleCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas(); // Directly access getCanvas from the ref
      if (canvas) {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Convert blob to URL for preview
              const croppedImageUrl = URL.createObjectURL(blob);
              setImageUrl(croppedImageUrl);
              // Use the original file name and type for the new file
              const file = new File([blob], profileImage.name, {
                type: profileImage.type,
                lastModified: Date.now(),
              });
              setProfileImage(file);
            }
          },
          profileImage.type,
          0.9 // Adjust the quality if needed (0.0 - 1.0)
        );
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the image size is greater than 5MB (5 * 1024 * 1024 bytes)
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.info("Please upload an image with a maximum size of 5MB.");
        return;
      }
      setProfileImage(file);
      setCropPopup(true);
    }
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.info("Please upload an image with a maximum size of 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSecondUrl(reader.result);
        setCoverImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Check if username is valid before proceeding if it has changed
    if (usernameChanged && !isUsernameValid) {
      toast.error("Username is not available.");
      return;
    }

    const updatedData = {
      fullName: name,
      userName: user,
      Designation: title,
      bio: description,
      profileImage: profileImage,
      coverImage: coverImage,
    };

    try {
      await editProfile(updatedData);
      setEditProfilePopup(false); // Close the edit profile popup if the update was successful
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating your profile.");
    }
  };

  const debouncedCheckUsernameAvailability = useCallback(
    _.debounce(async (username) => {
      try {
        const response = await checkUsernameAvailability(username);
        setIsUsernameValid(response?.available ?? false);
      } catch (error) {
        console.error("Error checking username availability:", error);
      }
    }, 5000),
    [checkUsernameAvailability]
  );

  useEffect(() => {
    if (usernameChanged) {
      debouncedCheckUsernameAvailability(user);
    } else {
      setIsUsernameValid(true); // Assume valid if unchanged
    }
  }, [user, usernameChanged, debouncedCheckUsernameAvailability]);

  const handleUserChange = (e) => {
    const newUsername = e.target.value;
    setUser(newUsername);
    setUsernameChanged(newUsername !== initialData?.userName); // Mark username as changed if it differs from initial
  };

  return (
    <div className="sm:max-w-[540px] sm:rounded-[40px] w-full bg-white sm:px-[23px] sm:pt-[43px] sm:pb-[54px] sm:py-[46px] sm:mx-4 fixed sm:top-[50%] sm:left-[50%] sm:-translate-y-1/2 sm:-translate-x-1/2 z-50 shadow max-sm:h-screen max-sm:top-0 max-sm:left-0">
      <div className="flex items-center sm:hidden my-4 ps-7">
        <button onClick={() => setEditProfilePopup(false)}>
          <BackArrowIcon />
        </button>
        <p className="text-base font-medium ms-2">{name || "User"}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-md font-medium hidden sm:block">Edit profile</p>
        <button className="hidden sm:block" onClick={() => setEditProfilePopup(false)}>
          <CloseIcon />
        </button>
      </div>
      {cropPopup && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-50 rounded-xl shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <Cropper
            src={URL.createObjectURL(profileImage)}
            className="w-[300px] h-[400px] sm:w-[400px] sm:h-[500px]"
            ref={cropperRef}
            aspectRatio={1}
            stencilProps={{
              resizable: true, // Disables resizing
              movable: true, // Allows moving the crop box (optional)
            }}
          />
          <div className="flex justify-between items-center mt-4 px-7">
            <button
              onClick={() => {
                handleCrop();
                setCropPopup(false);
              }}
              className="bg-primary p-2 text-white w-[100px] py-3 rounded-lg text-base">
              Crop
            </button>
            <button
              onClick={() => {
                setCropPopup(false);
              }}
              className="bg-red-700 p-2 text-white w-[100px] py-3 rounded-lg text-base">
              Cancel
            </button>
          </div>
        </div>
      )}
      <form onSubmit={handleSave}>
        <div
          className="relative border sm:mt-4 sm:max-w-[493px] sm:h-[149px] w-full h-[100px] bg-no-repeat bg-contain bg-center my-2"
          style={{ backgroundImage: `url(${secondUrl || dummy_img})` }}>
          <label className="absolute inset-0 flex justify-center items-center cursor-pointer">
            <input
              type="file"
              className="hidden h-full w-full"
              accept="image/*"
              onChange={handleImageChange2}
              id="coverImageInput"
            />
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
              <CameraIcon />
            </div>
          </label>
          {secondUrl && (
            <img
              src={secondUrl}
              alt="Uploaded Cover"
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>
        <div className="relative border sm:max-w-[137px] sm:h-[137px] max-w-[95px] h-[100px] rounded-[21px] sm:mt-[-69px] mt-[-50px] sm:ms-[23px] ms-[16px]">
          <label
            className="absolute inset-0 bg-no-repeat bg-contain bg-center flex justify-center items-center cursor-pointer bg-opacity-50 rounded-[21px]"
            style={{ backgroundImage: `url(${imageUrl || bansal_sndp})` }}>
            <input
              type="file"
              className="hidden "
              accept="image/*"
              onChange={handleImageChange}
              id="profileImageInput"
            />
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
              <CameraIcon />
            </div>
          </label>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Uploaded Profile"
              className="absolute inset-0 w-full h-full object-cover object-top"
            />
          )}
        </div>
        <div className="overflow-y-auto md:max-h-[300px] px-[23px]">
          <div className="sm:mt-6 mt-[30px]">
            <label className="text-sm font-medium">Full Name</label>
            <br />
            <input
              type="text"
              placeholder="Name"
              className="h-[40px] w-full sm:w-[328px] border border-1 border-[#BEC1C3] mt-1 rounded-[100px] ps-[26px] py-[12px]"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="sm:mt-[30px] mt-6">
            <label className="text-sm font-medium">Username</label>
            <br />
            <input
              type="text"
              value={user}
              onChange={handleUserChange}
              placeholder="Username"
              className={`h-[40px] w-full sm:w-[328px] border border-1 border-[#BEC1C3] mt-1 rounded-[100px] ps-[26px] py-[12px] ${
                isUsernameValid ? "border-[#BEC1C3]" : "border-red-500"
              }`}
            />
            {usernameChanged &&
              user &&
              (isUsernameValid ? (
                <p className="text-green-500 text-sm">Username is available.</p>
              ) : (
                <p className="text-red-500 text-sm">Username is not available.</p>
              ))}
          </div>
          <div className="sm:mt-[30px] mt-6">
            <label className="text-sm font-medium">Designation</label>
            <br />
            <input
              type="text"
              value={title}
              maxLength={40}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Designation"
              className="h-[40px] w-full sm:w-[328px] border border-1 border-[#BEC1C3] mt-1 rounded-[100px] ps-[26px] py-[12px]"
            />
          </div>
          <div className="sm:mt-[30px] mt-6">
            <label className="text-sm font-medium">Bio</label>
            <br />
            <input
              type="text"
              placeholder="Bio"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="h-[40px] w-full sm:w-[328px] border border-1 border-[#BEC1C3] mt-1 rounded-[100px] ps-[26px] py-[12px]"
            />
          </div>
          <div className="text-center mt-6 sm:mt-[30px]">
            <button
              type="submit"
              className="text-sm font-normal md:text-lg md:font-medium bg-[#4D8AFF] text-white md:px-10 md:py-[18px] rounded-[100px] px-6 py-[11px] sm:px-[30px] sm:py-[11px]">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfilePopup;
