import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../ContextApis/TokenStore";

const PrivateRoute = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   let login = localStorage.getItem("userName") || sessionStorage.getItem("userName");
  //   if (login === "Guest" || login == null) {
  //     navigate("/login");
  //   }
  // });
  const [username] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );

  if (location.pathname !== "/" && location.pathname !== "/login") {
    let token = getToken();
    if (token === null || username === "Guest") {
      localStorage.setItem("sharePath", location.pathname);
    }
  }
  
  
  return (
    <div>
      <Component />
    </div>
  );
};

export default PrivateRoute;
