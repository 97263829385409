import { createContext, useContext, useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import showToast from "../ToastSucess";
import showErrorToast from "../ToastError";
import { getToken } from "./TokenStore";
import { path } from "../common/Api";
const InterestContext = createContext();

export const InterestProvider = ({ children }) => {
  const [interestData, setInterestData] = useState([]);
  const [interestWithId, setInterestWithId] = useState([]);
  const [allInterest, setallInterest] = useState([]);
  const [searchInterest, setSearchInterest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({});
  const [blockeddata, setBlockedData] = useState([]);
  const [similardata, setSimilarData] = useState([]);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  let token = null;
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  if (localTokenStore === null) {
    token = sessionTokenStore;
  } else {
    token = localTokenStore;
  }

  function getTokenss() {
    let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
    let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
    let newToken = localTokenStore !== null ? localTokenStore : sessionTokenStore;
    return newToken;
  }
  // Get Interest data
  const getInterestData = async () => {
    let tokenss = getToken();
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenss?.accessToken}`,
      },
    };
    try {
      const response = await fetch(`${path}user/profile/interests/getinterests`, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch interest data");
      } else {
        if (data.message === "Access denied due to missing or invalid token.") {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        } else {
          setInterestData(Object.values(data.data));
          setInterestWithId(data.data);
        }
        setLoading(false);
      }
      return data.data;
    } catch (error) {
      if (error.message === "Access denied due to missing or invalid token.") {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      }
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  const getAllInterestData = async () => {
    let tokenss = getTokenss();
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenss?.accessToken}`,
      },
    };
    try {
      const response = await fetch(`${path}user/profile/interests/getallinterests`, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch interest data");
      } else {
        setallInterest(data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  // add Interest function
  const addInterest = async (interestTags) => {
    let tokens = getToken();
    setLoading(true);
    console.log("add interst");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
      body: JSON.stringify(interestTags),
    };
    try {
      const response = await fetch(`${path}user/profile/interests/addinterest`, requestOptions);

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add interest");
      } else {
        showToast(data.message);
        let fiter1 = interestTags.interests.filter((item) => !interestData.includes(item));
        let fiter2 = interestData.filter((item) => interestTags.interests.includes(item));
        setInterestData([...fiter1, ...fiter2]);
        getInterestData();
        setLoading(false);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  // delete Interest function
  const deteteInterest = async (data) => {
    setLoading(true);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
    };
    try {
      let interstId = Object.entries(interestWithId).map(([key, interest]) => {
        if (interest === data) {
          return key;
        }
      });
      let id = interstId.find((item) => typeof item === "string");
      const response = await fetch(
        `${path}user/profile/interests/removeinterest/${id}`,
        requestOptions
      );

      const errorData = await response.json();
      if (!response.ok) {
        throw new Error(errorData.message || "Failed to Delete interest");
      } else {
        console.log(data);
        setLoading(false);
        getInterestData();
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  // Get Interest data
  const searchInterestData = async (search) => {
    let tokens = getToken();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
    };

    try {
      const response = await fetch(
        `${path}user/profile/interests/search/${search}`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch interest data");
      } else {
        setSearchInterest(data.data);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  //  Notification Settings
  async function getNotificationSettings() {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };

      // Make the API call
      const response = await fetch(`${path}notifications/settings`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch notification settings");
      }
      const data = await response.json();
      setNotificationSettings(data.settings);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update Notification Settings
  async function updateNotificationSettings(updatedSetting) {
    console.log("update", updatedSetting);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(updatedSetting),
    };

    try {
      const response = await fetch(`${path}notifications/settings`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update notification settings");
      }
      const data = await response.json();

      // Update only the changed setting
      setNotificationSettings(updatedSetting);
    } catch (error) {
      console.error("Error updating notification settings:", error);
      if (error.message.includes("authentication")) {
        // navigate("/login");
      }
    }
  }
  // Reset Password
  const resetPassword = async (existingPassword, newgenratePassword, newPasswordConfirm) => {
    if (newgenratePassword !== newPasswordConfirm) {
      toast.error("New passwords do not match!");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        existingPassword: existingPassword,
        newPassword: newgenratePassword,
        newPasswordConfirm: newPasswordConfirm,
      }),
    };

    setLoading(true);
    try {
      const response = await fetch(
        `${path}auth/password/reset-password?token=${token?.accessToken}`,
        requestOptions
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to reset password");
      }
      const data = await response.json();
      toast.error(data.error);
      if (data.error === null) {
        toast.success("Password reset successful!");
      }

      return data;
    } catch (error) {
      console.error("There was a problem with the password reset operation:", error);
      toast.error("Failed to reset password: " + error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  // forgot password
  const forgotPassword = async (email, setPopup) => {
    setLoading(true);
    try {
      const response = await fetch(`${path}auth/password/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const errorData = await response.json();
      if (!response.ok) {
        showErrorToast(errorData.message);
        throw new Error(errorData.message || "Failed to send Otp");
      } else {
        setPopup(true);
        showToast(errorData.message);
      }
    } catch (err) {
      console.error("error:", err);
      console.error("Error details:", err.message, err.stack);
    } finally {
      setLoading(false);
    }
  };
  // verify otp
  const verifyOtp = async (otp, email) => {
    try {
      let data = {
        email: email,
        otp: otp,
      };
      const response = await fetch(`${path}otp/validateOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("OTP verification failed");
      }

      const result = await response.json();
      console.log(result.message);
      return response;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      return false; // OTP verification failed
    }
  };
  // deactivate account
  async function deactivateAccount(deactivateData) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(deactivateData),
      };
      // Make the API call
      const response = await fetch(`${path}deactivateanddelete/deactivate`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to report user");
      } else {
        showToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  }
  // delete account
  async function deleteAccount(deleteData) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(deleteData),
      };
      // Make the API call
      const response = await fetch(`${path}deactivateanddelete/initiateDelete`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to report user");
      } else {
        showToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  }
  // Get Blocked data
  const getblockedaccounts = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
    };
    try {
      const response = await fetch(`${path}people/block/blocked-users`, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch interest data");
      } else {
        setBlockedData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  // Get Blocked data
  const getsimilarpost = async (id) => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
    };
    try {
      const response = await fetch(`${path}posts/similar/${id}`, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch similar posts");
      } else {
        setSimilarData(data.posts);
        setLoading(false);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };
  // get filters
  const fetchInterestFilters = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.accessToken}`,
      },
    };

    try {
      const response = await fetch(`${path}user/profile/interests/filters`, requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch interest filters");
      } else {
        setFilters(data);
      }
    } catch (error) {
      console.error("Error fetching interest filters:", error);
      // showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InterestContext.Provider
      value={{
        getInterestData,
        getAllInterestData,
        allInterest,
        interestData,
        addInterest,
        searchInterestData,
        searchInterest,
        deteteInterest,
        setInterestData,
        getNotificationSettings,
        notificationSettings,
        setNotificationSettings,
        updateNotificationSettings,
        resetPassword,
        forgotPassword,
        verifyOtp,
        deactivateAccount,
        blockeddata,
        getblockedaccounts,
        similardata,
        getsimilarpost,
        deleteAccount,
        fetchInterestFilters,
        filters,
      }}>
      {loading && <Loader />}
      {children}
    </InterestContext.Provider>
  );
};

export const UseInterestContext = () => {
  return useContext(InterestContext);
};
