/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Filtericon,
  FilterIcon2,
  ForwardArrow,
  CloseIcon,
  RightArrowIcon,
  SearchIcon,
} from "../../components/common/Icons";
import Spotlight from "../Home/Spotlight";
import SeeAllButton from "../Home/SeeAllButton";
import InfoIcon from "../../assets/images/svg/updated.svg";
import Genres from "../../components/Home/Genres";
import { useState, useEffect, useRef } from "react";
import InfoPopup from "../../components/popup/InfoPopup";
import { Link, useParams } from "react-router-dom";
import EditInterest from "../../helpsettingpop/EditInterest";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import { UseHomeContext } from "../ContextApis/HomeContext";

import { UseProfileContext } from "../ContextApis/ProfileContext";
import { Helmet } from "react-helmet-async";
const Home = () => {
  const { getInterestData, filters, fetchInterestFilters, getAllInterestData } =
    UseInterestContext();
  const { profileData, getOtherUserProfileData } = UseProfileContext();
  const [selectedCat, setSelectedCat] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [showCategoryPopUp, setCategoryShowPopUp] = useState(false);
  const [showInfoPopup, setshowsetInfoPopup] = useState(false);
  const [showAddInterest, setshowAddInterest] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { username } = useParams();
  const [, setUserData] = useState({});
  if (showCategoryPopUp || showInfoPopup || showAddInterest) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  async function getUserProfileData() {
    const otherUserData = await getOtherUserProfileData(username);
    setUserData(otherUserData);
  }

  useEffect(() => {
    if (username !== undefined) {
      getUserProfileData();
    }
    if (username === undefined) {
      setUserData(profileData);
    }
  }, [username || profileData]);

  const {
    GetSpotlightdata,
    Spotlightdata,
    GetUpdatedcontents,
    updatedcontents,
    GetPopulargenres,
    topics,
    GetPopulargenresCategory,
    generesCategory,
  } = UseHomeContext();

  async function fatchSpotlight(topic, index) {
    if (topic === topics) {
      GetSpotlightdata("");
      GetUpdatedcontents("");
      GetPopulargenres("");
      sessionStorage.removeItem("topic");
    } else {
      GetSpotlightdata(topic);
      GetUpdatedcontents(topic);
      GetPopulargenres(topic);
      sessionStorage.setItem("topic", topic);
    }
  }
  async function AllData() {
    GetSpotlightdata("");
    GetUpdatedcontents("");
    GetPopulargenres("");
    sessionStorage.removeItem("topic");
  }
  const [, setToken] = useState(null);
  const [guestAcc, setguestAcc] = useState("");
  useEffect(() => {
    setToken(
      sessionStorage.getItem("tokens") != null
        ? sessionStorage.getItem("tokens")
        : localStorage.getItem("tokens")
    );
    setguestAcc(sessionStorage.getItem("userName"));
  }, []);

  async function callApis() {
    let storeTopic = sessionStorage.getItem("topic");
    let insData = await getInterestData();
    GetSpotlightdata(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
    GetUpdatedcontents(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
    GetPopulargenres(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
  }
  useEffect(() => {
    callApis();
    GetPopulargenresCategory();
    fetchInterestFilters();
    getAllInterestData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredCategories = Object.keys(filters).filter((category) =>
    category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectCategory = (category) => {
    // setSelectedCat(selectedCat === category ? null : category);
    setSelectedCat(category);
  };

  const handleSelectSubCat = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubCat([...selectedSubCat, value]);
    } else {
      setSelectedSubCat(selectedSubCat.filter((subCat) => subCat !== value));
    }
  };

  const handleSave = (e) => {
    const { value, checked } = e.target;
    setCategoryShowPopUp(false);
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      sessionStorage.setItem(
        "filters",
        JSON.stringify(
          checked
            ? [...selectedSubCat, value]
            : selectedSubCat.filter((subCat) => subCat !== value)
        )
      );

      GetSpotlightdata(
        topics,
        checked
          ? [...selectedSubCat, value]
          : selectedSubCat.filter((subCat) => subCat !== value)
      );
      GetUpdatedcontents(
        topics,
        checked
          ? [...selectedSubCat, value]
          : selectedSubCat.filter((subCat) => subCat !== value)
      );
    });

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    function preselectFilter() {
      let fil = JSON.parse(sessionStorage.getItem("filters"));
      setSelectedSubCat(fil ? fil : []);
    }
    preselectFilter();
  }, []);
  const { interestData, setInterestData } = UseInterestContext();
  const filteredData = [...interestData];

  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const scrollByAmount = (amount) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: amount, behavior: "smooth" });
    }
  };

  const handlePreviousPage = () => {
    scrollByAmount(-100);
  };

  const handleNextPage = () => {
    scrollByAmount(100);
  };

  function deleteInterestData(data) {
    let updateInterests = interestData.filter((item) => !data.includes(item));
    setInterestData(updateInterests);
  }

  const popupRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setScrollPosition(scrollContainerRef.current.scrollLeft);
        setIsScrollable(
          scrollContainerRef.current.scrollWidth >=
            scrollContainerRef.current.clientWidth
        );
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setshowAddInterest(false);
        setCategoryShowPopUp(false);
        setshowsetInfoPopup(false);
      }
    }

    if (showAddInterest || showInfoPopup || showCategoryPopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAddInterest, showInfoPopup, showCategoryPopUp]);

  return (
    <>
      <Helmet>
        <title>User Centric Content Platform</title>
        <meta
          name="description"
          content="Wised is a User centric content platform for Users to share & gain knowledge, Share a post related to educational content, Share notes & Question papers"
        />

        <meta property="og:url" content="https://Wised.in" />
      </Helmet>

      <div className="flex flex-col">
        {(showCategoryPopUp || showInfoPopup || showAddInterest) && (
          <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
        )}
        {showInfoPopup && (
          <div ref={popupRef}>
            <InfoPopup setshowsetInfoPopup={setshowsetInfoPopup} />
          </div>
        )}
        {guestAcc !== "Guest" && (
          <div className="  w-full z-[1] sticky top-[53px] max-lg:top-[59px] max-sm:top-[58px] bg-white">
            <div className="container mx-auto  p-4">
              <div className="flex justify-between items-center max-sm:flex-row-reverse">
                <button
                  className="flex items-center border border-grey rounded-[6px]  max-sm:h-[35px] h-10 sm:rounded-2xl py-3 px-4 fiter_btn max-md:py-[9px] max-sm:min-w-[36px] max-sm:w-[36px] max-sm:p-2 max-md:ps-[16px] max-md:pr-[10px] max-sm:justify-center max-sm:ml-4 "
                  onClick={() => setCategoryShowPopUp(true)}
                >
                  {window.screen <= 440 ? <Filtericon /> : <FilterIcon2 />}
                  <span className="text-black pl-2 text-base max-md:text-[14px] font-normal max-sm:hidden">
                    Filters
                  </span>
                </button>
                <div className="flex items-center space-x-1 sm:space-x-2 max-sm:border border-[#BEC1C3] rounded-[6px]  max-sm:px-[6px] max-sm:py-1">
                  {scrollPosition > 0 && (
                    <button
                      onClick={handlePreviousPage}
                      style={{ transform: "rotate(180deg)" }}
                      className="py-[13px] px-3.5 h-10 bg-light-400 rounded-[16px] pagination_icon flex items-center justify-center max-sm:h-[25px] max-sm:p-0 max-sm:min-w-[25px] max-sm:max-w-[25px]"
                    >
                      <ForwardArrow />
                    </button>
                  )}
                  <div
                    ref={scrollContainerRef}
                    className={`flex items-center sm:p-2 gap-3 max-sm:gap-1 overflow-auto scroll_hidden ${
                      scrollPosition > 0 &&
                      isScrollable &&
                      scrollPosition + scrollContainerRef.current.clientWidth <
                        scrollContainerRef.current.scrollWidth
                        ? "nav2_width2"
                        : "nav2_width1"
                    } sm:max-w-[55vw] md:max-w-[50vw] lg:max-w-[60vw]`}
                  >
                    <button
                      onClick={AllData}
                      className={`px-3 py-2 max-sm:py-[2px] max-sm:px-1 font-medium text-black text-base max-md:text-[14px] max-sm:text-[12px] rounded-2xl hover:bg-light-400 
                      ${topics === "" ? "bg-light-400" : ""}
                      `}
                    >
                      All
                    </button>
                    {filteredData.map((heading, index) => (
                      <div key={index} className="flex-none overscroll-x-auto">
                        <button
                          className={`px-3 py-2 max-sm:py-[2px] max-sm:px-1 font-medium text-black text-base max-md:text-[14px] max-sm:text-[12px] rounded-2xl hover:bg-light-400 ${
                            topics === heading ? "bg-light-400" : ""
                          }`}
                          onClick={() => fatchSpotlight(heading, index)}
                        >
                          {heading}
                        </button>
                      </div>
                    ))}
                    <div className="flex items-center justify-center">
                      <button
                        className="px-3 h-[40px] font-medium text-black text-base max-md:text-[14px] max-sm:h-[22px] max-sm:text-[12px] rounded-2xl bg-light-400 leading-sm flex items-center justify-center max-sm:px-2 whitespace-nowrap"
                        onClick={() => setshowAddInterest(true)}
                      >
                        Add new
                      </button>
                    </div>
                  </div>
                  {isScrollable &&
                    scrollPosition + scrollContainerRef.current.clientWidth <
                      scrollContainerRef.current.scrollWidth && (
                      <button
                        onClick={handleNextPage}
                        className="py-2 px-3.5 h-10 bg-light-400 rounded-[16px] pagination_icon flex items-center justify-center max-sm:h-[25px] max-sm:p-0 max-sm:min-w-[25px] max-sm:max-w-[25px]"
                      >
                        <ForwardArrow />
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container mx-auto xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4 px-4">
          {/* ----------------------- section 1 (spotlight)------------------------ */}
          {/* {Array.isArray(Spotlightdata) && Spotlightdata.length > 0 && (
            <section className="px-0 lg:px-6">
              <p className="text-black font-semibold sm:text-lg text-base text-center lg:pb-10 sm:pb-10 pb-4 sm:pt-4">
                Spotlight
              </p>
              <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
                {Spotlightdata.length > 0 &&
                  Spotlightdata.slice(0, 4).map((item, index) => {
                    return (
                      <div key={index} className={`mt-5 h-full`}>
                        <Spotlight data={item} index={index} />
                      </div>
                    );
                  })}
              </div>

              <div className=" text-center lg:mb-10 sm:mb-12 mb-4 pt-5">
                <Link to="/home/allspotlight">
                  <SeeAllButton name={"See All"} />
                </Link>
              </div>
              <hr />
            </section>
          )} */}

{
  Array.isArray(Spotlightdata) && Spotlightdata.length > 0 ? (
    <section className="px-0 lg:px-6">
      <p className="text-black font-semibold sm:text-lg text-base text-center lg:pb-10 sm:pb-10 pb-4 sm:pt-4">
        Spotlight
      </p>
      <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
        {Spotlightdata.slice(0, 4).map((item, index) => (
          <div key={index} className={`mt-5 h-full`}>
            <Spotlight data={item} index={index} />
          </div>
        ))}
      </div>

      <div className="text-center lg:mb-10 sm:mb-12 mb-4 pt-5">
        <Link to="/home/allspotlight">
          <SeeAllButton name={"See All"} />
        </Link>
      </div>
      <hr />
    </section>
  ) : (
    // Placeholder layout with fixed height
    <section className="px-0 lg:px-6">
      <p className="text-black font-semibold sm:text-lg text-base text-center lg:pb-10 sm:pb-10 pb-4 sm:pt-4">
        Spotlight
      </p>
      <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className="mt-5 bg-gray-200 animate-pulse"
            style={{ height: "400px", borderRadius: "8px" }} // Placeholder styles
          />
        ))}
      </div>

      <div className="text-center lg:mb-10 sm:mb-12 mb-4 pt-5">
        <p className="text-gray-500">Loading Spotlight...</p>
      </div>
      <hr />
    </section>
  )}
          {/* ------------------------ section 2 (updated contents)---------------------- */}
          {
  Array.isArray(updatedcontents) && updatedcontents.length > 0 ? (
    <section className="px-0 lg:px-6">
      <div className="flex items-center justify-center">
        <p className="text-black font-semibold sm:text-lg text-base text-center lg:py-10 sm:py-10 py-4 pe-2">
          Updated contents
        </p>
        <button
          className="ml-2"
          onClick={(e) => {
            e.preventDefault();
            setshowsetInfoPopup(true);
          }}
          aria-label="Show information popup"
        >
          <img src={InfoIcon} alt="Info Icon" />
        </button>
      </div>
      <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
        {updatedcontents.slice(0, 6).map((item, index) => (
          <div key={index} className={`mt-5`}>
            <Spotlight data={item} />
          </div>
        ))}
      </div>

      <div className="text-center lg:mb-10 sm:mb-12 mb-4 pt-5">
        <Link to="/home/allupdatedcontent" className="inline-block">
          <SeeAllButton name={"See All"} />
        </Link>
      </div>
      <hr />
    </section>
  ) : (
    // Placeholder layout with fixed height
    <section className="px-0 lg:px-6">
      <div className="flex items-center justify-center">
        <p className="text-black font-semibold sm:text-lg text-base text-center lg:py-10 sm:py-10 py-4 pe-2">
          Updated contents
        </p>
        <button
          className="ml-2"
          onClick={(e) => {
            e.preventDefault();
            setshowsetInfoPopup(true);
          }}
          aria-label="Show information popup"
        >
          <img src={InfoIcon} alt="Info Icon" />
        </button>
      </div>
      <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index}
            className="mt-5 bg-gray-200 animate-pulse"
            style={{ height: "400px", borderRadius: "8px" }}
          />
        ))}
      </div>

      <div className="text-center lg:mb-10 sm:mb-12 mb-4 pt-5">
        <p className="text-gray-500">Loading Updated Contents...</p>
      </div>
      <hr />
    </section>
  )}
          {/* --------------------section 3 (Popular generes)--------------------- */}

          {Array.isArray(generesCategory) && generesCategory.length > 0 && (
            <section className="px-0 lg:px-6">
              <div>
                <p className=" text-black font-semibold sm:text-lg text-base text-center lg:py-10 sm:py-10 py-4 pe-2">
                  Popular genres
                </p>
              </div>
              <div className="grid sm:grid-cols-3 grid-cols-2 lg:gap-x-[77px] sm:gap-x-[25px] gap-x-[16px]  lg:pb-10 sm:pb-12 pb-4 max-sm:gap-4">
                {generesCategory.slice(0, 3).map((item, index) => {
                  return (
                    <div className={` mt-5`}>
                      <Link key={index} to={`/home/populargenres/${item.name}`}>
                        <Genres data={item} index={index} />
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className=" text-center pt-5">
                <Link to="/home/allpopulargenres" className="inline-block">
                  <SeeAllButton name={"See All"} />
                </Link>
              </div>
            </section>
          )}
          {/* popup  */}

          {showCategoryPopUp && (
            <div
              ref={popupRef}
              className="flex flex-col max-w-[320px] sm:max-w-[450px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] popup_shadow translate-y-[-50%] py-3 w-full overflow-hidden mb-3 z-[60] h-[450px] "
            >
              <div
                className="absolute right-[20px] top-[15px] cursor-pointer"
                onClick={() => setCategoryShowPopUp(false)}
              >
                <CloseIcon />
              </div>
              <p className="text-md font-medium text-center">Category</p>
              <div className="flex items-center justify-between mx-[30px] bg-[#EEEFEF] rounded-[28px] mt-3 h-[40px] ps-[22px] pe-[33px]">
                <input
                  className="w-full bg-transparent outline-none"
                  placeholder="Search"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <SearchIcon />
              </div>
              <div className="my-4 overflow-auto h-full">
                {filteredCategories.map((category, index) => (
                  <div
                    key={category}
                    className="border-b-[1px] border-[#EEEFEF] last:border-0"
                  >
                    <div className="mx-[30px] py-3">
                      <div
                        className="flex justify-between w-full"
                        onClick={() => handleSelectCategory(category)}
                      >
                        <p className="sm:text-base text-sm cursor-pointer">
                          {category}
                        </p>
                        <div
                          className={`min-w-[33px] h-[33px] flex items-center justify-center cursor-pointer transition-transform ${
                            selectedCat === category ? "rotate-[90deg]" : ""
                          }`}
                        >
                          <RightArrowIcon />
                        </div>
                      </div>
                      {/* let */}
                      {/* Render Subcategories */}
                      {(selectedCat === category ||
                        Object.keys(filters[category]).includes(
                          selectedCat
                        )) && (
                        <div className="flex justify-center w-full">
                          <ul className="flex flex-col py-2 max-h-[200px] max-w-[350px] mx-auto overflow-auto w-full">
                            {Array.isArray(filters[category]) &&
                              filters[category].map((subCat, i) => (
                                <li
                                  key={subCat}
                                  className="flex items-center justify-start gap-4 mb-[10px] w-full"
                                >
                                  <label
                                    htmlFor={`${index}-${i}`}
                                    className="flex items-center gap-4 w-full"
                                  >
                                    <input
                                      onChange={handleSelectSubCat}
                                      id={`${index}-${i}`}
                                      value={subCat}
                                      checked={selectedSubCat.includes(subCat)}
                                      type="checkbox"
                                      className="form-checkbox"
                                    />
                                    <p className="sm:text-base text-sm text-[#303135]">
                                      {subCat}
                                    </p>
                                  </label>
                                </li>
                              ))}
                            {!Array.isArray(filters[category]) &&
                              Object.keys(filters[category]).map(
                                (subCategory) => (
                                  <div key={subCategory} className="my-2">
                                    <div
                                      onClick={() =>
                                        handleSelectCategory(subCategory)
                                      }
                                      className="flex items-center justify-between"
                                    >
                                      <p className="sm:text-base text-sm cursor-pointer">
                                        {subCategory}
                                      </p>
                                      <div
                                        className={`min-w-[33px] h-[33px] flex items-center justify-center cursor-pointer transition-transform ${
                                          subCategory === selectedCat
                                            ? "rotate-[90deg]"
                                            : ""
                                        }`}
                                      >
                                        <RightArrowIcon />
                                      </div>
                                    </div>

                                    {subCategory === selectedCat && (
                                      <ul>
                                        {filters[category][subCategory].map(
                                          (subCat, i) => (
                                            <li
                                              key={subCat}
                                              className="flex items-center justify-start gap-4 my-[10px] w-full"
                                            >
                                              <label
                                                htmlFor={`${index}-${i}-${subCat}`}
                                                className="flex items-center gap-4 w-full"
                                              >
                                                <input
                                                  onChange={handleSelectSubCat}
                                                  id={`${index}-${i}-${subCat}`}
                                                  value={subCat}
                                                  checked={selectedSubCat.includes(
                                                    subCat
                                                  )}
                                                  type="checkbox"
                                                  className="form-checkbox"
                                                />
                                                <p className="sm:text-base text-sm text-[#303135]">
                                                  {subCat}
                                                </p>
                                              </label>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                )
                              )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {filteredCategories.length === 0 && (
                  <p className="text-center">No result</p>
                )}
              </div>

              <div className="flex justify-center gap-3 mt-2">
                <button
                  onClick={handleSave}
                  className=" text-white font-normal lg:text-base text-sm  flex items-center justify-center bg-primary rounded-[100px] px-5 pt-1 pb-1.5"
                >
                  save
                </button>
              </div>
            </div>
          )}

          {showAddInterest && (
            <div ref={popupRef}>
              <EditInterest
                deleteInterestData={deleteInterestData}
                homePopup={"homeAddInterst"}
                setshowAddInterest={setshowAddInterest}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Home;
