import React, { useState } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../common/Icons";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";

const NotesPage = () => {
  const { notesData } = UseCreatePostData();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const totalPages = Math.ceil(notesData.length / itemsPerPage);

  const currentItems = notesData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function goBack() {
    navigate(-1);
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      scrollToTop();
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      scrollToTop();
    }
  };

  return (
    <div className="px-4">
      <div>
        <div className="flex justify-start ps-3 sm:ps-0 sm:justify-center items-center gap-4 my-4 sm:my-11">
          <button className="cursor-pointer" onClick={goBack}>
            <BackArrowIcon />
          </button>
          <p className="text-black font-semibold sm:text-lg text-base">Notes</p>
        </div>
        {currentItems.map((item, index) => (
          <>
            <h3 className="text-black font-semibold lg:text-lg xl:text-xl my-6 md:my-14 text-base sm:text-center">
              {item.name}
            </h3>
            <div key={index}>
              <Spotlight data={item} />
            </div>
          </>
        ))}

      </div>
    </div>
  );
};

export default NotesPage;
