import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { getToken } from "./TokenStore";
import { path } from "../common/Api";
const TopCreatorsContext = createContext();

export const TopCreatorsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [topCreators, setTopCreators] = useState([]);
  const [filterpeoplelocation, setFilterpeoplelocation] = useState([]);
  const [universityorschool, setUniversityorschool] = useState([]);
  const [location, setLocation] = useState("");
  const [university, setUniversity] = useState("");
  const [topUser, setTopUser] = useState([]);
  // Helper function to get token
  let token = null;
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  if (localTokenStore === null) {
    token = sessionTokenStore;
  } else {
    token = localTokenStore;
  }
  // Get top creators
  async function getTopCreators() {
    setLoading(true);

    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/byTopCreators`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch top creators");
      }
      const data = await response.json();
      setTopCreators(data.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }
  async function getPeoplebylocations() {
    setLoading(true);

    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/byLocation`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch filterPeoplebylocation");
      }
      const data = await response.json();
      setLocation(data.locations);
      setFilterpeoplelocation(data.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }
  async function getUniversityorSchool() {
    setLoading(true);

    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/byUniversityOrSchool`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch UniversityorSchool");
      }
      const data = await response.json();
      setUniversity(data.universityName);
      setUniversityorschool(data.profileToFollowMappedData);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }
  // Top 4 Creators
  async function getTopFourCreators() {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/top4users`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch top creators");
      }
      const data = await response.json();
      setTopUser(data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  //Followers api
  const followUser = async (userName) => {
    const storedUser = localStorage.getItem("userName");
  if (storedUser === "Guest") {
    console.error("Guest users cannot follow others.");
    return; 
  }

   else{
    let tokens = getToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
    };
    try {
      const response = await fetch(`${path}people/follow/${userName}`, requestOptions);

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to follow user");
      }
    } catch (error) {
      console.error("error:", error);
    }
   }
  };
  //unfollow api
  const unFollowUser = async (user) => {
    const storedUser = localStorage.getItem("userName");
  if (storedUser === "Guest") {
    console.error("Guest users cannot follow others.");
    return; 
  }else{
    let tokens = getToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokens?.accessToken}`,
      },
      body: JSON.stringify(""),
    };
    try {
      const response = await fetch(`${path}people/unfollow/${user}`, requestOptions);

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to unfollow to user");
      } else {
        console.log(data);
        return data;
      }
    } catch (error) {
      console.error("error:", error);
    }
  }

   
  };

  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getTopCreators();
      getPeoplebylocations();
      getUniversityorSchool();
      setUsername(localStorage.getItem("userName"));
    }
  }, [username]);
  
  return (
    <TopCreatorsContext.Provider
      value={{
        followUser,
        unFollowUser,
        getTopCreators,
        topCreators,
        location,
        university,
        getTopFourCreators,
        topUser,
        loading,
        getPeoplebylocations,
        getUniversityorSchool,
        universityorschool,
        filterpeoplelocation,
      }}>
      {loading && <Loader />}
      {children}
    </TopCreatorsContext.Provider>
  );
};

export const UseTopCreatorsContext = () => {
  return useContext(TopCreatorsContext);
};
