

import backward_arrow from "../../assets/images/svg/backward_arrow.svg";
import { Link } from "react-router-dom";
import { UseQuestionPaperContext } from "../ContextApis/QuestionPaper";
import { useEffect, useRef, useState } from "react";
import { DetailIcon } from "../common/Icons";
import { getToken } from "../ContextApis/TokenStore";
import { Helmet } from "react-helmet-async";

export default function QuestionPaper() {
  const componentRef = useRef();
  const { allQuestionPapers, getAllQuestionPaper } = UseQuestionPaperContext();
  const [username, setUsername] = useState("");
  useEffect(() => {
    let user =
      sessionStorage.getItem("userName") || localStorage.getItem("userName");
    setUsername(user);
  }, []);
  useEffect(() => {
    getAllQuestionPaper();
  }, []);

  return (
    <>
      <Helmet>
        <title>Question Papers for All Subjects and Exams | Wised.in</title>
        <meta
          name="description"
          content="Government Private Engineering Universities, State & Central Exams, Other exams"
        />

        <meta property="og:url" content="https://Wised.in/question-papers" />
      </Helmet>

      <div className="overflow-x-hidden min-h-full">
        <div className="absolute opacity-1 bottom-0 left-[-100%] h-screen">
          <div ref={componentRef}>Question Papers</div>
        </div>
        <div className="container  px-4 md:px-6 mx-auto h-full">
          <div className=" flex justify-start ps-3 sm:ps-0 sm:justify-center items-center gap-4 my-1  sm:mt-[54px] sm:mb-3  md:my-10">
            <img
              className="cursor-pointer"
              width={"18px"}
              onClick={() => window.history.back()}
              src={backward_arrow}
              alt="backward_arrow"
            />
            <p className=" text-black font-semibold sm:text-lg text-base ">
              Question papers
            </p>
          </div>
          {Array.isArray(allQuestionPapers) && allQuestionPapers.length > 0 && (
            <div className="mb-10">
              {allQuestionPapers.map((item, index) => {
                return (
                  <div key={index}>
                    <h3 className="text-black font-semibold lg:text-lg xl:text-xl my-6 md:my-14 text-base sm:text-center">
                      {item.name}
                    </h3>
                    <div className="flex items-center gap-4 sm:gap-9 lg:gap-x-14 md:gap-y-10  flex-wrap">
                      {item.contents.items
                        .sort((a, b) => {
                          const yearA = parseInt(
                            a.name.split("$$")[1].split("_")[1].split(".")[0]
                          );
                          const yearB = parseInt(
                            b.name.split("$$")[1].split("_")[1].split(".")[0]
                          );
                          return yearB - yearA;
                        })
                        .map((itm, i) => {
                          return (
                            <Link
                              target="_blank"
                              onClick={() => {
                                let token = getToken();
                                if (token === null || username === "Guest") {
                                  localStorage.setItem(
                                    "sharePath",
                                    `/showpdf2/${itm.name}=${itm.id}`
                                  );
                                }
                              }}
                              to={`/showpdf2/${itm.name}=${itm.id}`}
                              style={{
                                boxShadow: "0px 8px 20px 0px #2F327D1A",
                              }}
                              className="flex items-center justify-between px-[20px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[60px] md:h-[100px] lg:h-[100px] show_pdf"
                            >
                              <div className="overflow-hidden">
                                <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium whitespace-nowrap relative">
                                  {itm.name.includes("$$") &&
                                    itm.name
                                      .split("$$")[1]
                                      .split(".")[0]
                                      .split("_")[0]}{" "}
                                  {itm.name.includes("$$") &&
                                    itm.name
                                      .split("$$")[1]
                                      .split(".")[0]
                                      .split("_")[1]}
                                </p>
                              </div>
                              <button className="add_qus_btn ms-2">
                                <DetailIcon />
                              </button>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
