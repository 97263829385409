import React, { useState, useRef, useEffect, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Document, Page } from "react-pdf";
import wordIcon from "../../assets/images/png/word.png";
import excelIcon from "../../assets/images/jpeg/excel.jpg";
import pdfImg from "../../assets/images/png/pdficon.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  BackArrowIcon,
  FileUploadIcon,
  WriteUpIcon,
  CrossIcon,
  ImgUploadIcon,
  CropIcon,
  AddMoreIcon,
  SearchIcon,
  PreviewIcon,
  NextPrevArrow,
} from "../common/Icons";
import Writeup from "./Writeup";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ImagePreviewModal from "./ImagePreviewModal";
import Cropper from "react-easy-crop";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import showErrorToast from "../ToastError";
import { toast } from "react-toastify";

const CreatePost = ({ value, popupVisible }) => {
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const [showWriteup, setShowWriteup] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [, setSelectedFile] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [isImagePopupVisible, setIsImagePopupVisible] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [, setUploadedImage] = useState(null);
  const [showFinalContent, setShowFinalContent] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [documentImg, setDocumentImg] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isPostPopupVisible, setIsPostPopupVisible] = useState(false);
  const [userdata, setUserdata] = useState({ Category: "" });
  const [dropdownVisible, setDropdownVisible] = useState({
    Category: false,
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { ref } = useResizeDetector();
  const [showPopup, setShowPopup] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [, setCroppedImage] = useState(null);
  const [aspect, setAspect] = useState(4 / 3);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [, setShowOptions] = useState(false);
  const [blobImg, setBlobImg] = useState();
  const [type, setType] = useState("");
  const postAllData = {
    docTitle: "",
    type: type,
    description: message,
    userMentionedIds: "",
    files: blobImg,
    category: userdata.Category,
  };
  const { addPost, searchCategory, searchPostCategory } = UseCreatePostData();




  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const heightDiff = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(heightDiff > 0 ? heightDiff : 0); // Set the keyboard height only if it's positive
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleResize); // Backup resize listener for browsers not supporting visualViewport

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePrevImage = () => {
    if (uploadedImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? uploadedImages.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextImage = () => {
    if (uploadedImages.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === uploadedImages.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  const CategoryData = [...searchPostCategory];
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setUserdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setDropdownVisible({
      Category: name === "Category" && value.length > 0,
    });
  };
  useEffect(() => {
    if (userdata.Category.length > 0) {
      searchCategory(userdata.Category);
    }
  }, [searchCategory, userdata.Category]);
  const handleDropdownToggle = (name, value) => {
    setUserdata({ Category: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    return options
      .filter((option) => option.toLowerCase().includes(userdata[name].toLowerCase()))
      .map((option, index) => (
        <p
          key={index}
          className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 px-4 rounded my-1"
          onClick={() => handleDropdownToggle(name, option)}>
          {option}
        </p>
      ));
  };

  if (
    isPopupVisible ||
    isImagePopupVisible ||
    showWriteup ||
    showFinalContent ||
    showPreview ||
    isPostPopupVisible
  ) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  const handleDocumentImg = (e) => {
    const uploadDocument = e.target.files[0];
    setBlobImg(uploadDocument);
    if (uploadDocument) {
      const fileType = uploadDocument.type;
      if (fileType === "application/pdf") {
        setDocumentImg(pdfImg);
        setIsPopupVisible(true);
      } else if (
        fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileType === "application/msword"
      ) {
        setDocumentImg(wordIcon);
        setIsPopupVisible(true);
      } else if (
        fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        setDocumentImg(excelIcon);
        setIsPopupVisible(true);
      } else {
        setDocumentImg(URL.createObjectURL(uploadDocument));
        setIsPopupVisible(true);
      }
      // Reset the input field
      documentInputRef.current.value = "";
    }
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
    setUploadedImage(image);
  };

  const handleAddMoreImages = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setIsImagePopupVisible(true);
    } else {
      console.error("fileInputRef is not defined.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
    setIsImagePopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedFile(null);
  };

  const handleDataChange = (event) => {
    const files = event.target.files;
    setBlobImg([...files]);
    if (files.length > 0) {
      const newImages = [...uploadedImages];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          const imageUrl = URL.createObjectURL(file);
          newImages.push(imageUrl);
          setUploadedImages(newImages);
          setUploadedImage(imageUrl); 
          setSelectedImage(imageUrl); 
        } else if (file.type === "application/pdf") {
        
          newImages.push({ type: "pdf", url: file });
          setUploadedImages(newImages);
          setUploadedImage(newImages[newImages.length - 1]); 
          setSelectedImage(newImages[newImages.length - 1]); 
        }
      }
      setIsImagePopupVisible(true);
    } else {
      setSelectedFile(null);
      toast.info("Please select at least one image file");
    }
  };

  const handleNextClick = () => {
    setShowFinalContent(true);
    setIsImagePopupVisible(false);
    setIsPopupVisible(false);
  };

  const handlepostclick = () => {
    setIsPostPopupVisible(true);
  };
  const popupRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (showPreview) {
          setShowPreview(false);
        } else {
          setIsPopupVisible(false);
          setIsImagePopupVisible(false);
          // setShowWriteup(false);
          setShowFinalContent(false);
          setIsPostPopupVisible(false);
          // value(false);
        }
      }
    }
    if (showWriteup || isPopupVisible || isImagePopupVisible || showFinalContent || showPreview) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showWriteup, isPopupVisible, isImagePopupVisible, showFinalContent, showPreview]);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleAspectChange = useCallback(async () => {
    setCrop({ x: 0, y: 0 });
    try {
      const croppedImage = await getCroppedImg(
        uploadedImages[currentImageIndex],
        croppedArea,
        zoom
      );
      setCroppedImage(croppedImage);
      uploadedImages[currentImageIndex] = croppedImage;
      setShowPopup(false);
      setSelectedImage(croppedImage);

      // Convert the cropped image URL to a Blob
      const response = await fetch(croppedImage);
      const blob = await response.blob();

      // Determine the file type based on the blob's MIME type
      let fileExtension = "";
      switch (blob.type) {
        case "image/jpeg":
          fileExtension = "jpeg";
          break;
        case "image/png":
          fileExtension = "png";
          break;
        default:
          fileExtension = "jpg"; // Default to jpg if type is unknown
      }

      // Create a file name for the cropped image
      const fileName = `${Date.now()}-cropped-image.${fileExtension}`;
      // Convert the Blob into a File object
      const file = new File([blob], fileName, { type: blob.type });
      blobImg[currentImageIndex] = file;
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  }, [uploadedImages, currentImageIndex, croppedArea, zoom, blobImg]);

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onCropComplete = useCallback(( croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const getCroppedImg = async (imageSrc, croppedAreaPixels, zoom) => {
    const image = new Image();
    image.src = imageSrc;

    // Ensure image is fully loaded before manipulating
    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = croppedAreaPixels.width * zoom;
    canvas.height = croppedAreaPixels.height * zoom;

    try {
      ctx.drawImage(
        image,
        croppedAreaPixels.x * scaleX,
        croppedAreaPixels.y * scaleY,
        croppedAreaPixels.width * scaleX,
        croppedAreaPixels.height * scaleY,
        0,
        0,
        croppedAreaPixels.width * zoom,
        croppedAreaPixels.height * zoom
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          resolve(URL.createObjectURL(blob));
        }, "image/jpeg");
      });
    } catch (error) {
      console.error("Error in getCroppedImg:", error);
      throw error; // Propagate error for further handling
    }
  };

  
  // console.log("Loading:", user)
  function handleCreatePost() {
    const user= localStorage.getItem("userName")
    if (!user || user === "Guest") {
      showErrorToast("Please Login !");
      return; 
    }
    if (message.length !== 0) {
      addPost(postAllData);
      setIsPostPopupVisible(false);
      value(false);
    }  
    else {
      showErrorToast("Please put some text !");
    }
  }

  function closeAllPopups() {
    setShowWriteup(false);
    setIsImagePopupVisible(false);
    setIsPopupVisible(false);
    setIsPostPopupVisible(false);
    value(false);
  }

  function handleToast() {
    showErrorToast("All changes will be lost !");
    setSelectedImage(null);
    setUploadedImages([]);
    fileInputRef.current.value = null;
  }

  function handleAddMessage(content, delta, source, editor) {
    setMessage(editor.getHTML());
    setShowOptions(true);
  }

  function handleAddMessage2(content, delta, source, editor) {
    setMessage(editor.getHTML());
    setMessage2(message2);
  }

  return (
    <>
      {(showWriteup ||
        isPopupVisible ||
        isImagePopupVisible ||
        isPostPopupVisible ||
        popupVisible) && (
        <div
          onClick={closeAllPopups}
          className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {showWriteup && (
        <div ref={popupRef}>
          <Writeup value={setShowWriteup} setMainPopup={value} />
        </div>
      )}
      <div
        className={`bg-white fixed top-0 left-0 sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-[50] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] md:rounded-[40px] overflow-hidden h-full sm:max-h-[500px] 2xl:max-h-[676px] sm:w-[586px] w-full  sm:rounded-[40px] mx-auto  sm:mt-0 flex flex-col pt-4 sm:pt-[31px]`}>
        <div className="flex items-center justify-between px-4 sm:px-[38px]">
          <button onClick={() => value(false)} className="hidden sm:flex cursor-pointer">
            <CrossIcon />
          </button>
          <span className="sm:hidden cursor-pointer" onClick={() => value(false)}>
            <BackArrowIcon />
          </span>
          <div
            onClick={handleCreatePost}
            className="font-normal text-sm md:text-base leading-6 duration-500 h-[40px] w-[78px] sm:w-[106px] xl:w-[114px] xl:h-[60px] rounded-[100px] text-nowrap capitalize text-white flex items-center bg-[#4D8AFF] justify-center border cursor-pointer border-grey">
            Post
          </div>
        </div>
        <div className="mt-4 md:mt-[31px] flex-grow sm:px-4 post_editor">
          <ReactQuill
            onChange={handleAddMessage}
            value={message}
            modules={{ toolbar: false }}
            placeholder="Type your content here..."
            onFocus={() => setKeyboardHeight(keyboardHeight)}
            onBlur={() => setKeyboardHeight(0)}
          />
        </div>
        {!showFinalContent && (
          <div
            ref={ref}
            style={{
              backgroundColor: "white",
              padding: 16,
              position: window.innerWidth < 640 && "fixed",
              bottom: `${keyboardHeight}px`, // Adjust the bottom based on the keyboard height
              left: 0,
            }}
            className="flex w-full items-center justify-between px-4 sm:px-[38px] mt-4 mb-4 sm:mb-6">
            <button
              className="flex items-center gap-2"
              type="button"
              onClick={() => {
                handleImageClick();
                setType("IMAGES");
              }}>
              <ImgUploadIcon />
              <span className="text-black text-sm font-normal leading-6">Photo</span>
            </button>
            <button
              type="button"
              className="flex items-center gap-2"
              onClick={() => {
                documentInputRef.current.click();
                fileInputRef.current.value = "";
                setType("NOTES");
              }}>
              <FileUploadIcon />
              <span className="text-black text-sm font-normal leading-6">Document</span>
            </button>
            <input
              type="file"
              ref={documentInputRef}
              style={{ display: "none" }}
              onChange={handleDocumentImg}
              accept="*"
            />
            <button
              className="flex items-center gap-2"
              type="button"
              onClick={() => {
                setShowWriteup(true);
                setType("WRITE_UPS");
              }}>
              <WriteUpIcon />
              <span className="text-black text-sm font-normal leading-6">Write Up</span>
            </button>

            <input
              type="file"
              ref={fileInputRef}
              name={fileInputRef}
              style={{ display: "none" }}
              multiple
              onChange={handleDataChange}
            />
          </div>
        )}
        {showFinalContent && (
          <div className="max-sm:mx-4 rounded-[16px] overflow-hidden mt-4 max-sm:mb-10">
            <div className="flex justify-center items-center">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full max-h-[290px] 2xl:max-h-[350px] sm:max-h-[190px] xl:max-h-[192px] object-contain rounded-t-[16px]"
                />
              )}
            </div>
            <div
              className={`flex gap-2 justify-center py-1 sm:py-4 w-full ${
                uploadedImages.length === 0 ? "mt-72" : ""
              }  sm:rounded-bl-[40px] sm:rounded-br-[40px] bg-[#BEC1C3]`}>
              {uploadedImages.length > 1 && (
                <div className="px-2 flex flex-nowrap gap-2 overflow-x-auto uploadedImgScroll">
                  {uploadedImages.map((image, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handleImageSelect(image);
                          setCurrentImageIndex(index);
                        }}
                        className={`min-w-[94px] max-w-[94px] h-[70px] sm:min-w-[120px] sm:max-w-[120px] sm:h-20 cursor-pointer rounded-[20px] overflow-hidden border-[2px] sm:border-[3px] ${
                          currentImageIndex === index ? "border-[#4D8AFF]" : "border-white"
                        }`}>
                        <img
                          src={image}
                          alt={`Uploaded ${index}`}
                          className="w-full object-cover h-full"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isPopupVisible && (
        <div
          ref={popupRef}
          className="bg-white fixed top-0 sm:top-1/2 left-0 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-[50] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] md:rounded-[40px] h-screen sm:h-[500px] px-4 lg:px-[38px] 2xl:h-[676px] sm:w-[586px] w-full py-4 sm:py-[26px] md:py-[31px] sm:rounded-[18px] mx-auto">
          <p className="font-normal text-sm md:text-base">Document</p>
          <div className="flex justify-between w-full mb-4 md:mb-8 items-center">
            <span
              onClick={() => {
                handleClosePopup();
                handleToast();
              }}
              className="cursor-pointer">
              <BackArrowIcon />
            </span>
            <button
              onClick={handlepostclick}
              type="button"
              className="font-normal text-sm md:text-base leading-6 duration-500 h-[40px] w-[78px] sm:w-[106px] xl:w-[114px] xl:h-[60px] rounded-[100px] text-nowrap capitalize text-white flex sm:inline-block items-center bg-[#4D8AFF] justify-center border border-grey">
              Next
            </button>
          </div>
          <select
            className="border max-md:text-sm border-grey text-black outline-none rounded-[100px] mt-1 w-full h-[40px] px-6"
            onChange={(e) => {
              setType(e.target.value);
              setDocumentType(e.target.value);
            }}
            value={documentType}>
            <option disabled value="" className="text-[#636364]">
              Document type
            </option>
            <option className="text-[#636364]" value="NOTES">
              Notes
            </option>
            <option className="text-[#636364]" value="QUESTION_PAPERS">
              Question paper
            </option>
            <option className="text-[#636364]" value="UP_TO_DATE_CONTENTS">
              Updated contents
            </option>
            <option className="text-[#636364]" value="NONE">
              Other
            </option>
          </select>
          <div className="border border-[#BEC1C3] mt-[26px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none max-sm:text-sm h-[40px]"
              name="Category"
              id="Category"
              placeholder="Category"
              value={userdata.Category}
            />
            <SearchIcon className="absolute right-2 w-5 h-5 text-grey" />
            {dropdownVisible.Category && (
              <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full py-2 z-[1] rounded-xl dropdown max-h-[250px] overflow-y-auto">
                {renderDropdownOptions("Category", CategoryData)}
              </div>
            )}
          </div>
          <div className=" rounded-xl mt-6 overflow-hidden">
            <div className="flex justify-center items-center max-h-[250px] sm:max-h-[140px] 2xl:max-h-[300px]">
              {documentImg && (
                <>
                  {typeof documentImg === "string" ? (
                    <img
                      className="max-w-[30%] sm:max-w-[20%] 2xl:max-w-[43%]"
                      src={documentImg}
                      alt="Document Preview"
                    />
                  ) : (
                    <Document file={documentImg}>
                      <Page pageNumber={1} />
                    </Document>
                  )}
                </>
              )}
            </div>
            <div
              className="flex items-end justify-end cursor-pointer px-[27px] py-3 sm:py-4 bg-[#efefef9d]"
              onClick={() => setShowPreview(true)}>
              <PreviewIcon />
            </div>
          </div>
        </div>
      )}
      {isPostPopupVisible && (
        <div
          className="bg-white fixed left-0 top-0 sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-[50] sm:shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] md:rounded-[40px] max-h-[92vh] h-full sm:max-h-[500px] sm:px-4 lg:px-[38px]  2xl:max-h-[676px] sm:w-[586px] w-full py-4 sm:py-[26px] sm:rounded-[18px] mx-auto flex flex-col document_editor"
          ref={popupRef}>
          <p className="font-normal text-sm md:text-base max-sm:px-4">Document</p>
          <div className="flex justify-between w-full items-center max-sm:px-4">
            <span
              onClick={() => {
                setIsPostPopupVisible(false);
              }}
              className="cursor-pointer">
              <BackArrowIcon />
            </span>

            <button
              onClick={handleCreatePost}
              type="button"
              className="font-normal text-sm md:text-base leading-6 duration-500 h-[40px] w-[78px] sm:w-[106px] xl:w-[114px] xl:h-[60px] rounded-[100px] text-nowrap capitalize text-white flex sm:inline-block items-center bg-[#4D8AFF] justify-center border border-grey">
              Post
            </button>
          </div>
          <div className="flex-grow post_editor mt-4">
            <ReactQuill
              onChange={handleAddMessage2}
              value={message}
              modules={{ toolbar: false }}
              placeholder="Type your content here..."
            />
          </div>
          <div className="rounded-xl mt-4 overflow-hidden max-sm:px-4">
            <div className="flex px-[27px] items-center justify-between bg-[#efefef9d] py-3">
              <p className="sm:text-[16px] text-[12px] font-medium">Title of the document</p>
              <p className="sm:text-[16px] text-[12px] font-medium">Notes</p>
            </div>
            <div className="flex justify-center items-center">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full h-[150px] object-contain"
                />
              )}
              {documentImg && (
                <img src={documentImg} alt="Document" className="w-full h-[150px] object-contain" />
              )}
            </div>
            <div
              className="flex items-end justify-end bg-[#efefef9d] py-3 px-[27px] cursor-pointer"
              onClick={() => setShowPreview(true)}>
              <PreviewIcon />
            </div>
          </div>
        </div>
      )}
      {isImagePopupVisible && (
        <div
          ref={popupRef}
          className="bg-white fixed top-0 left-0 sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-[500] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] md:rounded-[40px] h-screen sm:h-[500px] 2xl:h-[676px] sm:w-[586px] w-full pt-4 sm:pt-[31px] sm:rounded-[18px] mx-auto sm:flex sm:flex-col sm:justify-between">
          {" "}
          <p className="font-normal text-sm md:text-base px-4 sm:px-8">Photo</p>
          <div className="flex items-center justify-between w-full px-4 sm:px-8 ">
            <span
              onClick={() => {
                setIsImagePopupVisible(false);
                handleToast();
              }}
              className="cursor-pointer">
              <BackArrowIcon />
            </span>
            <p className="font-medium sm:hidden">Add photos</p>
            <button
              type="button"
              onClick={handleNextClick}
              className="font-normal text-sm md:text-base leading-6 duration-500 h-[40px] w-[78px] sm:w-[106px] xl:w-[114px] xl:h-[60px] rounded-[100px] text-nowrap capitalize text-white flex sm:inline-block items-center bg-[#4D8AFF] justify-center border border-grey">
              Next
            </button>
          </div>
          <div className="relative w-full overflow-hidden bottom-0 mt-4 sm:mt-8">
            {uploadedImages.length > 0 && (
              <div className="relative">
                {uploadedImages.length > 1 && (
                  <div className="absolute w-full h-full left-0 top-0 z-[10] flex items-center justify-between px-10">
                    <span onClick={handlePrevImage} className="-scale-100 cursor-pointer">
                      <NextPrevArrow />
                    </span>
                    <span onClick={handleNextImage} className="cursor-pointer">
                      <NextPrevArrow />
                    </span>
                  </div>
                )}
                <div className="flex justify-center">
                  <div>
                   
                    <img
                      src={uploadedImages[currentImageIndex]}
                      alt="Original"
                      className="w-full max-h-[345px]  sm:max-h-[230px] min-[768px]:max-h-[220px] 2xl:max-h-[400px] object-contain"
                    />
                  </div>
                  {showPopup && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-20">
                      <div className="bg-white p-4 rounded-lg relative w-96">
                        <h2 className="text-xl mb-4">Crop Image</h2>
                        <div className="relative" style={{ width: "100%", height: "300px" }}>
                          <Cropper
                            image={uploadedImages[currentImageIndex]}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={onCropChange}
                            onZoomChange={onZoomChange}
                            onCropComplete={onCropComplete}
                          />
                        </div>
                        <div className="flex justify-center mt-4 space-x-2">
                          <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                            onClick={() => setAspect(1 / 1)}>
                            1:1
                          </button>
                          <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                            onClick={() => setAspect(4 / 3)}>
                            4:3
                          </button>
                          <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md"
                            onClick={() => setAspect(16 / 9)}>
                            16:9
                          </button>
                        </div>
                        <div className="flex justify-center mt-4 gap-4">
                          <button
                            className="bg-red-500 text-white px-4 py-2 rounded-md w-[84px]"
                            onClick={() => setShowPopup(false)}>
                            Cancel
                          </button>
                          <button
                            className="bg-primary text-white px-4 py-2 rounded-md w-[84px]"
                            onClick={() => {
                              setShowPopup(false);
                              handleAspectChange();
                            }}>
                            save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className={`flex gap-2 justify-center pe-4 py-1 sm:py-4 w-full ${
              uploadedImages.length === 0 ? "mt-72" : ""
            }  sm:rounded-bl-[40px] sm:rounded-br-[40px] bg-[#BEC1C3]`}>
            {uploadedImages.length > 1 && (
              <div className="px-2 flex flex-nowrap gap-2 overflow-x-auto uploadedImgScroll">
                {uploadedImages.map((image, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handleImageSelect(image);
                        setCurrentImageIndex(index);
                      }}
                      className={`min-w-[94px] max-w-[94px] h-[70px] sm:min-w-[120px] sm:max-w-[120px] sm:h-20 cursor-pointer rounded-[20px] overflow-hidden border-[2px] sm:border-[3px] ${
                        currentImageIndex === index ? "border-[#4D8AFF]" : "border-white"
                      }`}>
                      <img
                        src={image}
                        alt={`Uploaded ${index}`}
                        className="w-full object-cover h-full"
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <button onClick={handleButtonClick}>
              <CropIcon />
            </button>
            <button
              onClick={() => {
                handleAddMoreImages();
              }}>
              <AddMoreIcon />
            </button>
          </div>
        </div>
      )}
      {showPreview && (
        <div className="relative z-[100]">
          <ImagePreviewModal
            show={showPreview}
            onClose={setShowPreview}
            imgSrc={selectedImage || documentImg}
          />
        </div>
      )}
    </>
  );
};

export default CreatePost;
