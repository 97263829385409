/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import LandingPageHeroImg from "../../assets/images/png/landing-hero-img.png";
import LandingPageHeroImg2 from "../../assets/images/png/landing-page-hero-img2.png";
import LandingPageHeroImg3 from "../../assets/images/webp/landing-hero-img.webp";
import Create from "../../assets/images/svg/Create.svg";
import Prepare from "../../assets/images/svg/Prepare.svg";
import IndeustryReady from "../../assets/images/png/IndustryReady.png";
import Placeholder from "../../assets/images/png/placeholder.png";
import PlaceholderMd from "../../assets/images/svg/placeholder-md.svg";
import PlaceholderSm from "../../assets/images/webp/placeholder.webp";
import AddIcon from "../../assets/icons/Add.svg";
import { StreamlinedIcon } from "../common/Icons";
import { PersonalisedIcon } from "../common/Icons";
import { PeerToIcon } from "../common/Icons";
import { WealthIcon } from "../common/Icons";
import { UpToDateIcon } from "../common/Icons";
import { TopicsIcon } from "../common/Icons";
import { CollaborativeIcon } from "../common/Icons";
import { Link, useNavigate } from "react-router-dom";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import userImg from "../../assets/images/png/user-img2.png";
export default function LandingPage() {
  const curruntYear = new Date().getFullYear();
  const [openTab, setOpenTab] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { getTopFourCreators, topUser } = UseTopCreatorsContext();
  const toggleTab = (tabIndex) => {
    setOpenTab(openTab === tabIndex ? null : tabIndex);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const peopleToShow = windowWidth < 768 ? topUser?.slice(0, 3) : topUser?.slice(0, 4);

  const navigate = useNavigate();
  useEffect(() => {
    getTopFourCreators();
  }, []);
  return (
    <>
      <header>
        <div className="flex max-md:flex-col-reverse items-center justify-between max-md:max-h-[661px] max-md:min-h-full min-h-[100vh] ps-[78px] max-xl:ps-[39px] pe-[39px] max-sm:px-0 overflow-hidden relative pt-7">
          <div className="max-md:text-center max-md:max-w-full max-lg:max-w-[70%] max-sm:max-w-full">
            <h1 className="text-[64px] max-[1307px]:text-[51px] max-[1836px]:text-[58px] max-2xl:text-[52px] max-xl:text-[38px] max-lg:text-[32px] max-md:text-[20px] max-2xl:max-w-[700px] max-lg:max-w-[500px] max-md:max-w-[400px] max-sm:min-w-[300px] max-w-[752px] font-medium max-md:mx-auto max-md:absolute relative max-md:left-[50%] max-md:translate-x-[-50%] z-10 top-[20px]">
              Empowering Educators, Inspiring Learners
            </h1>
            <img
              className="w-full hidden sm:block md:hidden relative top-0"
              src={LandingPageHeroImg2}
              width={400}
              height={300}
              loading="lazy"
              alt="LandingPageHeroImg"
            />
            <img
              className="w-full sm:hidden relative top-0"
              src={LandingPageHeroImg3}
              width={400}
              height={300}
              loading="lazy"
              alt="LandingPageHeroImg"
            />
            <p className="text-[28px] max-xl:text-[22px] max-lg:text-[18px] max-sm:text-[14px] text-teritary opacity-70 max-lg:max-w-[500px] max-w-[637px] max-md:min-w-[330px] max-sm:min-w-[250px] max-2xl:max-w-[500px] my-16 py-3 max-2xl:py-0 max-2xl:mb-12 max-2xl:mt-10 max-lg:my-7 max-md:absolute relative max-md:bottom-[75px] max-sm:bottom-[65px]  max-md:translate-x-[-50%] max-md:left-[50%] z-10 max-sm:mb-0 max-sm:mt-8">
              User centric social content platform for you to share and gain knowledge.
            </p>
            <div className="inline-block">
              <Link 
              aria-label="Get started button"
                to={localStorage.getItem("tokens") !== null ? "/home" : "/login"}
                className="mt-4 md:h-[60px] xl:h-[60px] h-[40px] px-[40px] max-lg:px-7 max-sm:px-[24px] bg-primary text-white rounded-[100px] text-lg max-lg:text-[18px] max-sm:text-[14px] font-medium flex items-center">
                Get started
              </Link>
            </div>
          </div>
          <div className="relative w-[60%] h-full">
            <img
              className="w-full absolute right-0 top-[50%] translate-y-[-50%] max-md:hidden"
              src={LandingPageHeroImg}
              width={400}
              height={300}
              loading="lazy"
              alt="LandingPageHeroImg"
            />
          </div>
        </div>
        <div className="max-w-[1920px] px-[26px] max-xl:px-[53px] max-sm:px-[26px] py-10 mt-5 mx-auto create_prepare_industry">
          <div className="flex justify-evenly items-center flex-wrap gap-y-[64px] flex_no_wrap">
            <div className="create_prepare w-[33.33%] min-w-[486px] max-xl:max-w-[450px] max-lg:min-w-full max-lg:w-full flex justify-between items-center max-lg:gap-7 max-sm:gap-0">
              <img width={200} height={200} loading="lazy" className="max-lg:w-[196px] max-sm:w-[139px]" src={Create} alt="" />
              <div className="ps-7">
                <h2 className="font-medium text-[36px] max-md:text-[24px] max-xl:text-[30px] max-sm:text-[18px] text-[#000000]">
                  Create
                </h2>
                <p className="font-normal text-lg max-md:text-[16px] max-xl:text-[18px] max-sm:text-[14px] text-[#303135] opacity-70 mt-[17px] max-md:leading-7 max-sm:leading-5 leading-[33px]">
                  Create and share engaging documents, images, and more to enhance your learning
                  experience.
                </p>
              </div>
            </div>
            <div className="create_prepare w-[33.33%] min-w-[486px] max-xl:max-w-[450px] max-lg:min-w-full max-lg:w-full flex justify-between items-center max-lg:flex-row-reverse flex_row_reverse max-lg:gap-7 max-sm:gap-0">
              <img width={200} height={200} loading="lazy" className="max-lg:w-[162px] max-sm:w-[115px] max-lg:ms-7" src={Prepare} alt="" />
              <div className="lg:ps-7">
                <h2 className="font-medium text-[36px] max-md:text-[24px] max-xl:text-[30px] max-sm:text-[18px] text-[#000000]">
                  Prepare
                </h2>
                <p className="font-normal text-lg max-md:text-[16px] max-xl:text-[18px] max-sm:text-[14px] text-[#303135] opacity-70 mt-[17px] max-md:leading-7 max-sm:leading-5 leading-[33px]">
                  Get ready for exams with tailored study contents, question papers, notes and more.
                </p>
              </div>
            </div>
            <div className="create_prepare w-[33.33%] min-w-[486px] max-lg:min-w-full max-lg:w-full flex justify-between items-center max-lg:gap-7 max-sm:gap-0">
              <img width={200} height={200} loading="lazy" className="max-lg:w-[178px] max-sm:w-[125px]" src={IndeustryReady} alt="" />
              <div className="ps-7">
                <h2 className="font-medium text-[36px] max-md:text-[24px] max-xl:text-[30px]  max-sm:text-[18px] text-[#000000]">
                  Industry-Ready
                </h2>
                <p className="font-normal text-lg max-md:text-[16px] max-xl:text-[18px] max-sm:text-[14px] text-[#303135] opacity-70 mt-[17px] max-md:leading-7 max-sm:leading-5 leading-[33px]">
                  Stay industry-ready with the latest content, trends, and skills that match current
                  market demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className="overflow-hidden">
          <div className="bg-[#F3F7FF] py-[99px] max-lg:py-[70px] max-md:py-[52px] mt-24 max-md:mt-7 max-lg:mt-10 px-7 max-sm:ps-4 max-md:px-[49px] max-sm:pe-0">
            <div className="max-w-[1170px] m-auto">
              <h2 className="text-[44px] max-xl:text-[36px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[18px] font-medium text-center text-[#000000] max-sm:pe-4">
                Your Personalized Learning & Networking Hub
              </h2>
              <div className="flex justify-between items-center mt-[100px] max-md:mt-[52px] max-lg:mt-[70px]">
                <div className="max-w-[726px] max-sm:max-w-[50%]">
                  <div className="flex items-start">
                    <div className="max-sm:w-[29px] w-[45px] max-sm:h-[29px] h-[45px] network_hub_icon">
                      <StreamlinedIcon />
                    </div>
                    <p className="text-xl max-lg:text-[18px] max-md:text-[16px] max-sm:text-sm font-normal ms-2">
                      <span className="text-primary">Streamlined environment</span> for sharing
                      valuable information.
                    </p>
                  </div>
                  <div className="flex items-start mt-[60px] max-lg:mt-12 max-md:mt-6">
                    <div className="max-sm:w-[29px] w-[45px] max-sm:h-[29px] h-[45px] network_hub_icon">
                      <PersonalisedIcon />
                    </div>
                    <p className="text-xl max-lg:text-[18px] max-md:text-[16px] max-sm:text-sm font-normal ms-2">
                      <span className="text-primary">Personalised recommendations </span>
                      tailored to each user's preferences and goals.
                    </p>
                  </div>
                  <div className="flex items-start mt-[60px] max-lg:mt-12 max-md:mt-6">
                    <div className="max-sm:w-[29px] w-[45px] max-sm:h-[29px] h-[45px] network_hub_icon">
                      <PeerToIcon />
                    </div>
                    <p className="text-xl max-lg:text-[18px] max-md:text-[16px] max-sm:text-sm font-normal ms-2">
                      <span className="text-primary">Peer to peer learning</span> and collaboration.
                    </p>
                  </div>
                </div>
                <div className="min-w-[406px] max-lg:min-w-[350px] max-sm:min-w-[unset] max-md:min-w-[320px] ms-3 flex justify-end">
                  <img className="w-100 max-md:hidden" src={Placeholder} alt="Placeholder" />
                  <img
                    className="w-100 hidden max-md:block max-sm:hidden"
                    src={PlaceholderMd}
                    alt="PlaceholderMd"

                  />
                  <img
                    className="w-100 hidden max-sm:block"
                    src={PlaceholderSm}
                    alt="PlaceholderSm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-[1714px] py-[100px] max-xl:py-20 max-lg:py-16 max-sm:py-13 m-auto px-[30px] max-sm:px-[12px]">
            <h2 className="text-[44px] max-xl:text-[36px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[18px] font-medium text-center text-[#000000]">
              We also offer you
            </h2>
            <div className="flex justify-between max-xl:justify-center items-stretch h-full mt-[100px] max-sm:mt-[52px] max-md:mt-[60px] max-lg:mt-16 max-xl:mt-20 flex-wrap max-sm:gap-y-[41px] gap-y-[54px]">
              <div className="px-2 max-sm:px-0 w-[25%] max-lg:mr-[25px] max-xl:mr-[20px] max-sm:mr-[8px] max-xl:w-[50%] max-md:w-[220px] max-sm:w-[47%] max-w-[340px] max-xl:max-w-[300px] we_also_card flex justify-end">
                <div className="px-[21px] max-md:w-[220px] max-sm:max-w-[155px] max-sm:w-full py-6 max-sm:pb-2 max-md:px-[17px] max-sm:px-2 max-sm:rounded-[4px] max-md:rounded-[4px]  rounded-[24px] shadow-[0.23px_1.14px_11.43px_0px_rgba(84,99,122,0.1)] h-full max-md:h-[179px] max-md:pt-[13px] max-lg:h-[290px] max-sm:h-[172px]">
                  <div className="flex justify-center we_also_icons">
                    <CollaborativeIcon />
                  </div>
                  <p className="text-xl max-md:text-[16px] max-sm:text-sm font-normal text-[#000000] text-center mt-[14px]">
                    <span className="text-primary">Collaborative space</span> <br /> for knowledge
                    sharing and experimentation.
                  </p>
                </div>
              </div>
              <div className="px-2 max-sm:px-0 w-[25%] max-xl:ml-[25px] max-lg:mr-[0px] max-xl:mr-[40px] max-sm:ml-[8px] max-xl:w-[50%] max-md:w-[220px] max-sm:w-[47%] max-w-[340px] max-xl:max-w-[300px] we_also_card">
                <div className="px-[21px] max-md:w-[220px] max-sm:max-w-[155px] max-sm:w-full py-6 max-sm:pb-2 max-sm:px-2  max-md:px-[17px] rounded-[24px] max-md:rounded-[4px] shadow-[0.23px_1.14px_11.43px_0px_rgba(84,99,122,0.1)] h-full max-md:h-[179px] max-md:pt-[13px] max-sm:h-[172px] max-lg:h-[290px]">
                  <div className="flex justify-center we_also_icons">
                    <WealthIcon />
                  </div>
                  <p className="text-xl max-md:text-[16px] max-sm:text-sm font-normal text-[#000000] text-center mt-[14px]">
                    <span className="text-primary">Wealth of resources </span>
                    <br />
                    available anytime, anywhere.
                  </p>
                </div>
              </div>
              <div className="px-2 max-sm:px-0 w-[25%] max-lg:mr-[25px] max-xl:w-[50%] max-md:w-[220px] max-sm:mr-[8px] max-sm:w-[47%] max-w-[340px] max-xl:max-w-[300px] we_also_card flex justify-end">
                <div className="px-[21px] max-md:w-[220px] max-sm:max-w-[155px] max-sm:w-full py-6 max-sm:pb-2 max-sm:px-2 max-md:px-[17px] rounded-[24px] max-md:rounded-[4px] shadow-[0.23px_1.14px_11.43px_0px_rgba(84,99,122,0.1)] h-full max-md:h-[179px] max-md:pt-[13px] max-sm:h-[172px] max-lg:h-[290px]">
                  <div className="flex justify-center we_also_icons">
                    <TopicsIcon />
                  </div>
                  <p className="text-xl max-md:text-[16px] max-sm:text-sm font-normal text-[#000000] text-center mt-[14px]">
                    <span className="text-primary">Topics & Interests </span>
                    <br />
                    related contents using a range of educational materials.
                  </p>
                </div>
              </div>
              <div className="px-2 max-sm:px-0 w-[25%] max-xl:ml-[40px] max-lg:ml-[25px] max-sm:ml-[8px]  max-xl:w-[50%] max-md:w-[220px] max-sm:w-[47%] max-w-[340px] max-xl:max-w-[300px] we_also_card">
                <div className="px-[21px] max-md:w-[220px] max-sm:max-w-[155px] max-sm:w-full py-6 max-sm:pb-2 max-sm:px-2  max-md:px-[17px] rounded-[24px] max-md:rounded-[4px] shadow-[0.23px_1.14px_11.43px_0px_rgba(84,99,122,0.1)] h-full max-md:h-[179px] max-md:pt-[13px] max-sm:h-[172px] max-lg:h-[290px]">
                  <div className="flex justify-center we_also_icons">
                    <UpToDateIcon />
                  </div>
                  <p className="text-xl max-md:text-[16px] max-sm:text-sm font-normal text-[#000000] text-center mt-[14px]">
                    <span className="text-primary">Up-to-date</span> <br /> study contents with the
                    latest trends and information in industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="bg-[#F3F7FF] py-[99px] max-lg:py-[70px] max-md:py-[52px] px-[30px] max-sm:px-1">
            <div className="max-w-[1320px] m-auto">
              <h2 className="text-[44px] max-xl:text-[36px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[18px] font-medium text-center text-[#000000] max-sm:w-[233px] mx-auto">
                We have industry experts who will guide you
              </h2>
              <div className="flex justify-between mt-[100px] max-lg:mt-[70px] max-md:mt-[52px] gap-y-16 max-md:gap-y-7 h-full">
                {Array.isArray(peopleToShow) &&
                  peopleToShow.length > 0 &&
                  peopleToShow.map((user, index) => {
                    return (
                      <Link
                        to={`/ViewByUserprofile/${user.userName}`}
                        key={index}
                        className="w-[25%] max-xl:w-[33.33%] px-3 max-sm:px-2 max-w-[270px]">
                        <div className="border rounded-lg overflow-hidden shadow-lg bg-white h-full">
                          <img
                            className="w-full h-[170px] lg:h-[244px] shadow-none bg-[#f7f7f7] object-cover object-top"
                            src={user.profileImageUrl || userImg}
                            alt="DevImg"
                          />
                          <div className="px-[31px] max-lg:pt-[9px] max-lg:px-[8px] max-sm:pb-[2px] max-sm:pt-[4px] max-lg:pb-[13px] py-[18px] bg-white">
                            <p className="text-xl max-md:text-[18px] max-sm:text-[12px] max-lg:text-[18px] max-xl:text-[20px] font-medium">
                              {user.fullName}
                            </p>
                            <p className="text-lg max-lg:text-[16px] max-sm:text-[10px] max-xl:text-[18px] font-medium my-3 max-sm:my-2 text-[#303135]">
                              {user.designation}
                            </p>
                            <p className="max-md:pb-2 text-lg max-lg:text-[16px] max-sm:text-[10px] max-xl:text-[18px] font-medium my-3 max-sm:my-2 text-[#303135]">
                              {user.company}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="max-w-[1431px] px-4 sm:px-[30px] mx-auto">
            <h2 className="text-[44px] max-xl:text-[36px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[18px] font-medium text-center text-[#000000] mx-auto my-[100px] max-lg:my-[70px] max-md:my-[52px]">
              Frequently asked questions
            </h2>
            <div className="mb-[100px] max-lg:mb-[70px] max-md:mb-[52px]">
              <div className="border-[1px] border-[#EEEFEF] rounded-[16px] max-md:rounded-[4px] max-md:pt-4 pt-6 px-[14px]">
                <button
                  onClick={() => toggleTab(1)}
                  className="w-full text-start text-[16px] flex items-center justify-between max-md:pb-4 pb-6">
                  <span className="flex items-center">
                    <span className="mr-2">
                      <img
                        className="max-md:w-[15px]"
                        style={{
                          transform: openTab === 1 ? "rotate(45deg)" : "rotate(0)",
                          transition: "0.3s",
                        }}
                        width={15}
                        height={15}
                        src={AddIcon}
                        alt="addIcon"
                      />
                    </span>
                    1. How do I create an account ?
                  </span>
                </button>
                {openTab === 1 && (
                  <div className="px-[14px] max-md:py-4 py-6 border-t-[1px] border-[#EEEFEF] -mx-[14px]">
                    <p className="text-sm">
                      To create an account on our platform: <br /> 1. Click on the "Sign up" link,
                      located below the "Sign In" button on the login screen. <br />
                      2. You have two options: - Continue with Google, GitHub, or Facebook: Click on
                      the corresponding icon to use your existing account from one of these
                      platforms. - Manual Sign Up: Enter your full name, email address, and create a
                      password. Confirm your password by entering it again. <br />
                      3. Optionally, check the "Remember me" box to stay logged in on this device.{" "}
                      <br /> 4. Click the "Sign Up" button to complete the process. <br /> 5. After
                      signing up, you will receive a One-Time Password (OTP) sent to your registered
                      email address. <br /> 6. Enter the OTP in the prompted field on the platform
                      to verify your email address and activate your account.
                    </p>
                  </div>
                )}
              </div>
              <div className="border-[1px] border-[#EEEFEF] rounded-[16px] max-md:rounded-[4px] max-md:pt-4 pt-6 px-[14px] mt-[40px] max-sm:mt-[22px]">
                <button
                  onClick={() => toggleTab(2)}
                  className="w-full text-start text-[16px] flex items-center justify-between max-md:pb-4 pb-6">
                  <span className="flex items-center">
                    <span className="mr-2">
                      <img
                      width={15}
                      height={15}
                        className="max-md:!w-[15px]"
                        style={{
                          transform: openTab === 2 ? "rotate(45deg)" : "rotate(0)",
                          transition: "0.3s",
                        }}
                        src={AddIcon}
                        alt="addIcon"
                      />
                    </span>
                    2. What should I do if I forget my username or password ?
                  </span>
                </button>
                {openTab === 2 && (
                  <div className="px-[14px] max-md:py-4 py-6 border-t-[1px] border-[#EEEFEF] -mx-[14px]">
                    <p className="text-sm">
                      If you forget your password: <br />
                      1. On the "Sign In" screen, click on the "Forgot Password?" link located just
                      below the password input field. <br /> 2. A popup will appear asking you to
                      enter your registered email address. <br /> 3. Enter your email address in the
                      provided field. <br /> 4. Click the "Continue" button. <br />
                      5. We will send a verification code to your email address. Once you receive
                      the code, follow the instructions in the email to reset your password. If you
                      forget your username: - Since your email address is used as the username for
                      logging in, simply enter the email you registered with. If you're unsure which
                      email was used, search your inbox for previous communications from our
                      platform or contact customer support for help.
                    </p>
                  </div>
                )}
              </div>
              <div className="border-[1px] border-[#EEEFEF] rounded-[16px] max-md:rounded-[4px] max-md:pt-4 pt-6 px-[14px] mt-[40px] max-sm:mt-[22px]">
                <button
                  onClick={() => toggleTab(3)}
                  className="w-full text-start text-[16px] flex items-center justify-between max-md:pb-4 pb-6">
                  <span className="flex items-center">
                    <span className="mr-2">
                      <img
                        className="max-md:w-[15px]"
                        style={{
                          transform: openTab === 3 ? "rotate(45deg)" : "rotate(0)",
                          transition: "0.3s",
                        }}
                        width={15}
                        height={15}
                        src={AddIcon}
                        alt="addIcon"
                      />
                    </span>
                    3. How do I reset my password ?
                  </span>
                </button>
                {openTab === 3 && (
                  <div className="px-[14px] max-md:py-4 py-6 border-t-[1px] border-[#EEEFEF] -mx-[14px]">
                    <p className="text-sm">
                      To reset your password: <br /> 1. On the "Sign In" page, click on "Forgot
                      Password?" just below the password input field. <br />
                      2. Enter your registered email address in the prompted field. <br />
                      3. Check your email for a verification code that we'll send you. <br /> 4.
                      Enter the verification code and follow the instructions to set a new password.{" "}
                      <br /> 5. Confirm your new password by entering it again and submit to update
                      your password.
                    </p>
                  </div>
                )}
              </div>
              <div className="border-[1px] border-[#EEEFEF] rounded-[16px] max-md:rounded-[4px] max-md:pt-4 pt-6 px-[14px] mt-[40px] max-sm:mt-[22px]">
                <button
                  onClick={() => toggleTab(4)}
                  className="w-full text-start text-[16px] flex items-center justify-between max-md:pb-4 pb-6">
                  <span className="flex items-center">
                    <span className="mr-2">
                      <img
                        className="max-md:w-[15px]"
                        style={{
                          transform: openTab === 4 ? "rotate(45deg)" : "rotate(0)",
                          transition: "0.3s",
                        }}
                        width={15}
                        height={15}
                        src={AddIcon}
                        alt="addIcon"
                      />
                    </span>
                    4. How do I log in to the platform ?
                  </span>
                </button>
                {openTab === 4 && (
                  <div className="px-[14px] max-md:py-4 py-6 border-t-[1px] border-[#EEEFEF] -mx-[14px]">
                    <p className="text-sm">
                      To log in to the platform: <br />
                      1. On the "Sign In" page, enter your email address in the first field. <br />
                      2. Enter your password in the second field. <br /> 3. You can also log in
                      using Google, GitHub, or Facebook by clicking on the corresponding icon at the
                      top of the login form. <br />
                      4. Optionally, check the "Remember me" box to stay logged in on this device.{" "}
                      <br />
                      5. Click on the "Sign In" button to access your account.
                    </p>
                  </div>
                )}
              </div>
              <div className="border-[1px] border-[#EEEFEF] rounded-[16px] max-md:rounded-[4px] max-md:pt-4 pt-6 px-[14px] mt-[40px] max-sm:mt-[22px]">
                <button
                  onClick={() => toggleTab(5)}
                  className="w-full text-start text-[16px] flex items-center justify-between max-md:pb-4 pb-6">
                  <span className="flex items-center">
                    <span className="mr-2">
                      <img
                        className="max-md:w-[15px]"
                        style={{
                          transform: openTab === 5 ? "rotate(45deg)" : "rotate(0)",
                          transition: "0.3s",
                        }}
                        width={15}
                        height={15}
                        src={AddIcon}
                        alt="addIcon"
                      />
                    </span>
                    5. What should I do if I haven't received the account verification email ?
                  </span>
                </button>
                {openTab === 5 && (
                  <div className="px-[14px] max-md:py-4 py-6 border-t-[1px] border-[#EEEFEF] -mx-[14px]">
                    <p className="text-sm">
                      If you haven’t received the OTP email for verification: <br /> 1. Check your
                      spam or junk email folder in case it was mistakenly filtered there. <br />
                      2. Ensure that you entered the correct email address when signing up. <br />{" "}
                      3. If the email is not in your spam folder, click on the "Resend OTP" option
                      on the verification page to receive a new OTP. <br /> 4. If you still don’t
                      receive the OTP email, contact our customer support for further assistance.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="max-md:py-[40px] max-sm:py-[18px] py-[80px] bg-primary">
            <div className="max-w-[900px] m-auto">
              <p className="text-lg max-lg:text-[16px] max-sm:text-xs font-normal text-center text-white">
                UPSKILL FOR A BETTER FUTURE
              </p>
              <h2 className="text-2xl max-2xl:text-[60px] max-xl-[54px] max-lg:text-[50px] max-md:text-[18px] text-center font-medium text-white  tracking-[-2%] mt-4 max-md:mt-2">
                Request More Information
              </h2>
              <div className="text-center flex justify-center">
                <button
                  onClick={() => navigate("/login")}
                  className="min-h-[40px] max-md:px-[24px] px-[40px] bg-white text-primary rounded-[100px] text-lg max-md:text-[14px] font-medium max-md:mt-[28px] max-lg:mt-[46px] mt-[46px] flex items-center md:h-[60px] xl:h-[60px] h-[40px]">
                  Get started
                </button>
              </div>
              <p className="text-base max-md:text-xs font-normal max-md:mt-[28px] max-lg:mt-[46px] mt-[46px] text-white text-center">
                ©{curruntYear} by Wised INC <br /> All Rights Reserved by Wised INC @ {curruntYear}
              </p>
              <div className="text-center mt-[81px] max-md:mt-[28px] max-lg:mt-[50px]">
                <Link
                  to="/privacypolicy"
                  className="text-base max-md:text-xs font-normal text-white hover:opacity-75">
                  Terms & Privacy
                </Link>
                <a
                  className="text-base max-md:text-xs font-normal text-white hover:opacity-75 ms-[88px]"
                  href="#">
                  Cookies
                </a>
              </div>
              <div className="flex justify-center mt-[26px]">
                <a
                  href=" https://www.linkedin.com/company/wised-inc/"
                  target="_blank"
                  className="me-[40px] w-[73px] h-[73px]  max-md:w-[35px] max-md:h-[35px] border-[4.91px] max-md:border-[2px] max-sm:border-[1px] border-[#ffffff29] flex items-center justify-center rounded-[50%] footer_icon">
                  <svg
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.13654 6.45862C4.66304 6.45862 5.90051 5.22114 5.90051 3.69464C5.90051 2.16814 4.66304 0.930664 3.13654 0.930664C1.61003 0.930664 0.372559 2.16814 0.372559 3.69464C0.372559 5.22114 1.61003 6.45862 3.13654 6.45862Z"
                      fill="#F5F8FA"
                    />
                    <path
                      d="M5.43985 8.30127H0.833221C0.578936 8.30127 0.372559 8.50765 0.372559 8.76193V22.5818C0.372559 22.8361 0.578936 23.0425 0.833221 23.0425H5.43985C5.69414 23.0425 5.90051 22.8361 5.90051 22.5818V8.76193C5.90051 8.50765 5.69414 8.30127 5.43985 8.30127Z"
                      fill="#F5F8FA"
                    />
                    <path
                      d="M19.1648 7.66659C17.196 6.99218 14.7333 7.58459 13.2564 8.64688C13.2057 8.4488 13.0251 8.30138 12.8105 8.30138H8.20383C7.94954 8.30138 7.74316 8.50776 7.74316 8.76205V22.5819C7.74316 22.8362 7.94954 23.0426 8.20383 23.0426H12.8105C13.0647 23.0426 13.2711 22.8362 13.2711 22.5819V12.65C14.0155 12.0088 14.9746 11.8043 15.7596 12.1378C16.5206 12.4593 16.9564 13.2443 16.9564 14.29V22.5819C16.9564 22.8362 17.1628 23.0426 17.4171 23.0426H22.0237C22.278 23.0426 22.4844 22.8362 22.4844 22.5819V13.3622C22.4319 9.5765 20.6509 8.17516 19.1648 7.66659Z"
                      fill="#F5F8FA"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/people/Wised-inc/100094642637177/"
                  target="_blank"
                  rel="noreferrer"
                  className="me-[40px] w-[73px] h-[73px] max-md:w-[35px] max-md:h-[35px] border-[4.91px] max-md:border-[2px] max-sm:border-[1px] border-[#ffffff29] flex items-center justify-center rounded-[50%] footer_icon">
                  <svg
                    width="15"
                    height="24"
                    viewBox="0 0 15 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.72304 23.8673L4.68951 13.5535H0.302246V9.13328H4.68951V6.18647C4.68951 2.20945 7.13396 0.292847 10.6553 0.292847C12.342 0.292847 13.7917 0.419368 14.2142 0.475917V4.63213L11.772 4.63325C9.85688 4.63325 9.48606 5.55011 9.48606 6.89553V9.13328H14.9265L13.464 13.5535H9.48605V23.8673H4.72304Z"
                      fill="#F5F8FA"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/wisedinc/"
                  target="_blank"
                  rel="noreferrer"
                  className="me-[40px] w-[73px] h-[73px] max-md:w-[35px] max-md:h-[35px] border-[4.91px] max-md:border-[2px] max-sm:border-[1px] border-[#ffffff29] flex items-center justify-center rounded-[50%] footer_icon">
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.843 7.38791C17.5486 7.38791 18.1206 6.81592 18.1206 6.11034C18.1206 5.40475 17.5486 4.83276 16.843 4.83276C16.1374 4.83276 15.5654 5.40475 15.5654 6.11034C15.5654 6.81592 16.1374 7.38791 16.843 7.38791Z"
                      fill="#F5F8FA"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.00342 11.5771C6.00342 14.5129 8.39088 16.9003 11.3266 16.9003C14.2624 16.9003 16.6498 14.5129 16.6498 11.5771C16.6498 8.64137 14.2624 6.25391 11.3266 6.25391C8.39088 6.25391 6.00342 8.64137 6.00342 11.5771ZM8.66503 11.5771C8.66503 10.1093 9.85876 8.91554 11.3266 8.91554C12.7945 8.91554 13.9882 10.1093 13.9882 11.5771C13.9882 13.045 12.7945 14.2388 11.3266 14.2388C9.85876 14.2388 8.66503 13.045 8.66503 11.5771Z"
                      fill="#F5F8FA"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.00339 22.2235H16.6498C19.386 22.2235 21.973 19.6364 21.973 16.9003V6.25388C21.973 3.51775 19.386 0.930664 16.6498 0.930664H6.00339C3.26726 0.930664 0.680176 3.51775 0.680176 6.25388V16.9003C0.680176 19.6364 3.26726 22.2235 6.00339 22.2235ZM3.34178 6.25393C3.34178 5.00963 4.75909 3.59233 6.00339 3.59233H16.6498C17.8941 3.59233 19.3114 5.00963 19.3114 6.25393V16.9004C19.3114 18.1447 17.8941 19.562 16.6498 19.562H6.00339C4.73514 19.562 3.34178 18.1686 3.34178 16.9004V6.25393Z"
                      fill="#F5F8FA"
                    />
                  </svg>
                </a>
                <a
                  href="https://x.com/wised_inc"
                  target="_blank"
                  rel="noreferrer"
                  className=" w-[73px] h-[73px] max-md:w-[35px] max-md:h-[35px] border-[4.91px] max-md:border-[2px] max-sm:border-[1px] border-[#ffffff29] flex items-center justify-center rounded-[50%] footer_icon">
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.2048 0.25H20.5128L13.2858 8.51L21.7878 19.75H15.1298L9.91584 12.933L3.94984 19.75H0.639844L8.36984 10.915L0.214844 0.25H7.03984L11.7528 6.481L17.2048 0.25ZM16.0438 17.77H17.8768L6.04484 2.126H4.07784L16.0438 17.77Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}
