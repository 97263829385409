import React from "react";
import shape from "../../assets/images/svg/Shape.svg";
import { Link, useNavigate } from "react-router-dom";

function Terms() {
  const navigate =useNavigate();
  function goback(){
    navigate(-1)
  }
  return (
    <div className="px-4 sm:px-5 md:px-6 sm:pb-10 flex flex-col">
      <Link
        to="/verification"
        className="flex items-center gap-4 sticky top-[0px] bg-white w-full py-10 max-md:py-8 max-sm:pt-3 max-sm:pb-6">
        <img className="cursor-pointer" onClick={goback} width={"18px"} height={15} src={shape} alt="shape" />
        <span className="text-base text-black font-semibold">Terms Of Use</span>
      </Link>
      <p className="font-normal  text-xs sm:text-base text-black md:leading-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum
        est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin
        lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
        lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
        himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
        rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi
        convallis convallis diam sit amet lacinia. Aliquam in elementum tellus. Curabitur tempor
        quis eros tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi.
        Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex
        eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a,
        blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius
        volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem
        condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo. Nam
        pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at
        nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus
        non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu,
        faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit
        sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat
        dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas
        ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra
        consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.
        Vestibulum dictum ultrices elit a luctus. Sed in ante ut leo congue posuere at sit amet
        ligula. Pellentesque eget augue nec nisl sodales blandit sed et sem. Aenean quis finibus
        arcu, in hendrerit purus. Praesent ac aliquet lorem. Morbi feugiat aliquam ligula, et
        vestibulum ligula hendrerit vitae. Sed ex lorem, pulvinar sed auctor sit amet, molestie a
        nibh. Ut euismod nisl arcu, sed placerat nulla volutpat aliquet. Ut id convallis nisl. Ut
        mauris leo, lacinia sed elit id, sagittis rhoncus odio. Pellentesque sapien libero, lobortis
        a placerat et, malesuada sit amet dui. Nam sem sapien, congue eu rutrum nec, pellentesque
        eget ligula. Nunc tempor interdum ex, sed cursus nunc egestas aliquet. Pellentesque interdum
        vulputate elementum. Donec erat diam, pharetra nec enim ut, bibendum pretium tellus.
        Vestibulum et turpis nibh. Cras vel ornare velit, ac pretium arcu. Cras justo augue, finibus
        id sollicitudin et, rutrum eget metus. Suspendisse ut mauris eu massa pulvinar sollicitudin
        vel sed enim. Pellentesque viverra arcu et dignissim vehicula. Donec a velit ac dolor
        dapibus pellentesque sit amet at erat. Phasellus porttitor, justo eu ultrices vulputate,
        nisi mi placerat lectus, sed rutrum tellus est id urna. Aliquam pellentesque odio metus, sit
        amet imperdiet nisl sodales eu. Quisque viverra nunc nec vestibulum dapibus. Integer nec
        diam a libero tincidunt varius sed vel odio. Donec rutrum dapibus massa, vel tempor nulla
        porta id. Suspendisse vulputate fermentum sem sollicitudin facilisis. Aliquam vehicula
        sapien nec ante auctor, quis mollis leo tincidunt. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
        nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
        Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
        enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus
        nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet
        lacinia. Aliquam in elementum tellus. Korem ipsum dolor sit amet, consectetur adipiscing
        elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
        fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
        Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
        enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus
        nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet
        lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros tempus lacinia. Nam
        bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo
        vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum.
        Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh.
        Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed
        lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum
        ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo. Nam pulvinar
        blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi
        nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non
        odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu,
        faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit
        sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat
        dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas
        ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra
        consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.
        Vestibulum dictum ultrices elit a luctus. Sed in ante ut leo congue posuere at sit amet
        ligula. Pellentesque eget augue nec nisl sodales blandit sed et sem. Aenean quis finibus
        arcu, in hendrerit purus. Praesent ac aliquet lorem. Morbi feugiat aliquam ligula, et
        vestibulum ligula hendrerit vitae. Sed ex lorem, pulvinar sed auctor sit amet, molestie a
        nibh. Ut euismod nisl arcu, sed placerat nulla volutpat aliquet. Ut id convallis nisl. Ut
        mauris leo, lacinia sed elit id, sagittis rhoncus odio. Pellentesque sapien libero, lobortis
        a placerat et, malesuada sit amet dui. Nam sem sapien, congue eu rutrum nec, pellentesque
        eget ligula. Nunc tempor interdum ex, sed cursus nunc egestas aliquet. Pellentesque interdum
        vulputate elementum. Donec erat diam, pharetra nec enim ut, bibendum pretium tellus.
        Vestibulum et turpis nibh. Cras vel ornare velit, ac pretium arcu. Cras justo augue, finibus
        id sollicitudin et, rutrum eget metus. Suspendisse ut mauris eu massa pulvinar sollicitudin
        vel sed enim. Pellentesque viverra arcu et dignissim vehicula. Donec a velit ac dolor
        dapibus pellentesque sit amet at erat. Phasellus porttitor, justo eu ultrices vulputate,
        nisi mi placerat lectus, sed rutrum tellus est id urna. Aliquam pellentesque odio metus, sit
        amet imperdiet nisl sodales eu. Quisque viverra nunc nec vestibulum dapibus. Integer nec
        diam a libero tincidunt varius sed vel odio. Donec rutrum dapibus massa, vel tempor nulla
        porta id. Suspendisse vulputate fermentum sem sollicitudin facilisis. Aliquam vehicula
        sapien nec ante auctor, quis mollis leo tincidunt. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
        nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
        Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
        enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus
        nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet
        lacinia. Aliquam in elementum tellus. Korem ipsum dolor sit amet, consectetur adipiscing
        elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
        fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
        Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
        enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus
        nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet
        lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros tempus lacinia. Nam
        bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo
        vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum.
        Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh.
        Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed
        lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum
        ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo. Nam pulvinar
        blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi
        nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non
        odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu,
        faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit
        sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat
        dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas
        ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra
        consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.
        Vestibulum dictum ultrices elit a luctus. Sed in ante ut leo congue posuere at sit amet
        ligula. Pellentesque eget augue nec nisl sodales blandit sed et sem. Aenean quis finibus
        arcu, in hendrerit purus. Praesent ac aliquet lorem. Morbi feugiat aliquam ligula, et
        vestibulum ligula hendrerit vitae. Sed ex lorem, pulvinar sed auctor sit amet, molestie a
        nibh. Ut euismod nisl arcu, sed placerat nulla volutpat aliquet. Ut id convallis nisl. Ut
        mauris leo, lacinia sed elit id, sagittis rhoncus odio. Pellentesque sapien libero, lobortis
        a placerat et, malesuada sit amet dui. Nam sem sapien, congue eu rutrum nec, pellentesque
        eget ligula. Nunc tempor interdum ex, sed cursus nunc egestas aliquet. Pellentesque interdum
        vulputate elementum. Donec erat diam, pharetra nec enim ut, bibendum pretium tellus.
        Vestibulum et turpis nibh. Cras vel ornare velit, ac pretium arcu. Cras justo augue, finibus
        id sollicitudin et, rutrum eget metus. Suspendisse ut mauris eu massa pulvinar sollicitudin
        vel sed enim. Pellentesque viverra arcu et dignissim vehicula. Donec a velit ac dolor
        dapibus pellentesque sit amet at erat. Phasellus porttitor, justo eu ultrices vulputate,
        nisi mi placerat lectus, sed rutrum tellus est id urna. Aliquam pellentesque odio metus, sit
        amet imperdiet nisl sodales eu. Quisque viverra nunc nec vestibulum dapibus. Integer nec
        diam a libero tincidunt varius sed vel odio. Donec rutrum dapibus massa, vel tempor nulla
        porta id. Suspendisse vulputate fermentum sem sollicitudin facilisis. Aliquam vehicula
        sapien nec ante auctor, quis mollis leo tincidunt. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
        nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.
        Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
        enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus
        nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet
        lacinia. Aliquam in elementum tellus.
      </p>
    </div>
  );
}

export default Terms;
