import React, { useEffect, useState } from "react";
import Genres from "../../components/Home/Genres";
import { LeftIcon } from "../../components/common/Icons";
import { Link, useParams } from "react-router-dom";
import { UseHomeContext } from "../ContextApis/HomeContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";
const AllPopularGenres = () => {
  const { generesCategory } = UseHomeContext();
  const [userData, setUserData] = useState({});
  const { profileData, getOtherUserProfileData } = UseProfileContext();
  const { username } = useParams();

  useEffect(() => {
    if (username !== undefined) {
      getOtherUserProfileData();
    }
    if (username === undefined) {
      setUserData(profileData);
    }
  }, [username || profileData]);
  return (
    <>
      <section className="px-6 max-sm:px-4">
        <div className="lg:pt-[30px] sm:pt-[50px] pt-4 flex items-center sm:justify-center justify-start lg:pb-[30px] sm:pb-[50px] pb-4">
          <Link to="/home"  aria-label="Go back to Home">
            <LeftIcon />
          </Link>
          <span className=" text-black font-semibold sm:text-lg text-base text-center ">
            Popular Genres
          </span>
        </div>
        <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
        
              {generesCategory.map((item, index) => {
                return (
                  <div key={index} aria-label={`View ${item} genre`}>
                    <Link to={`/home/populargenres/${item}`}  >
                      <Genres data={item} />
                    </Link>
                  </div>
                );
              })}
       
        </div>
      </section>
    </>
  );
};
export default AllPopularGenres;
