import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import showToast from "../ToastSucess";
import showErrorToast from "../ToastError";
import { toast } from "react-toastify";
import { getToken } from "./TokenStore";
import { path } from "../common/Api";
const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [professionalDtls, setProfessionalDtls] = useState([]);
  const [educationDtls, setEducationDtls] = useState([]);
  const [followersData, setFollowersData] = useState(null);
  const [followingData, setFollowingData] = useState(null);
  const [searchUniversityData, setSearchUniversityData] = useState([]);
  const [, setPostData] = useState(null); // New state for posts
  // Add Education
  async function addEducation(educationData) {
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(educationData),
      };
      // Make the API call
      const response = await fetch(`${path}user/profile/education/add-education`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to add education");
      } else {
        setEducationDtls([...educationDtls, ...data.data]);
        showToast(data.message);
        console.log("response:", data.data);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    }
  }
  // Edit Education
  async function updateEducation(id, educationData) {
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(educationData),
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/education/edit-education/${id}`,
        requestOptions
      );
      // Check if the response is not okay
      const errorData = await response.json();
      if (!response.ok) {
        throw new Error(errorData.message || "Failed to add education");
      } else {
        showToast(errorData.message);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    }
  }
  // Get Education Details
  async function getEducationDetails(access) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken || access}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}user/profile/education/get-education`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length > 0) {
        localStorage.setItem("education_details", JSON.stringify(data.data[0]));
      }
      setEducationDtls(data.data);

    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Delete Education
  async function deleteEducation(id) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/education/remove-education?id=${id}`,
        requestOptions
      );
      // Check if the response is not okay
      const errorData = await response.json();
      if (!response.ok) {
        throw new Error(errorData.message || "Failed to delete education");
      } else {
        showToast(errorData.message);
        setLoading(false);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Post Professional Details
  async function addProfessional(professionalData) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(professionalData),
      };
      // Make the API call
      const response = await fetch(`${path}user/profile/professional-details`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Add Profesional Details");
      } else {
        showToast(data.message);
        setProfessionalDtls([...professionalDtls, ...data.data]);
        setLoading(false);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Post Professional Details
  async function editProfessionalDetails(professionalData, id) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(professionalData),
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/professional-details/${id}`,
        requestOptions
      );
      // Check if the response is not okay
      const errorData = await response.json();
      if (!response.ok) {
        throw new Error(errorData.message || "Failed to Edit Professional Details");
      } else {
        showToast(errorData.message);
        setLoading(false);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Get Professional Details
  async function getProfessionalDetails() {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/professional-details/get-professional-details`,
        requestOptions
      );
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Professional Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setProfessionalDtls(data.data);
      // console.log(data.data, "data");

    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Delete Profesional Details
  async function deleteProfessionalDetails(id) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/professional-details/${id}`,
        requestOptions
      );
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Delete Profesional Details");
      } else {
        showToast(data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Search University
  async function searchUniversity(search) {
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/education/search/${search}`,
        requestOptions
      );
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To search university");
      } else {
        const data = await response.json();
        setSearchUniversityData(data.data);
        // console.log(data.data,"data");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  // Get Profile data
  async function getProfileData() {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call

      const response = await fetch(`${path}user/profile`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch profile data");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setProfileData(data.data);
      return data.data; // Store the fetched data in state
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false after the request
    }
  }
  // Get another user Profile data
  async function getOtherUserProfileData(otherUser) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call

      const response = await fetch(`${path}user/profile/${otherUser}`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch profile data");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false after the request
    }
  }
  // Add Profile data
  async function addProfile(newPersondata, setActiveSteps, activeSteps) {
    let token = getToken();
    const formData = new FormData();
    formData.append("userName", newPersondata.userName);
    formData.append("dob", newPersondata.dob);
    formData.append("fullName", newPersondata.fullName);
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token?.accessToken}`,
      },
      body: formData,
    };

    setLoading(true); // Set loading to true before the request
    try {
      const response = await fetch(`${path}user/profile/update`, requestOptions);

      const responseData = await response.json();
      if (!response.ok) {
        showErrorToast(responseData.error);
        throw new Error(responseData.message || "Failed to add profile");
      } else {
        localStorage.setItem("userName", newPersondata.userName);
        setActiveSteps(activeSteps + 1);
        setProfileData(responseData.data);
        showToast(responseData.message);
        await getProfileData();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false after the request
    }
  }
  // Edit Profile data
  async function editProfile(updatedData) {
    let token = getToken();
    const formData = new FormData();
    formData.append("userName", updatedData.userName);
    if (updatedData.bio) {
      formData.append("bio", updatedData.bio);
    }
    if (updatedData.Designation) {
      formData.append("Designation", updatedData.Designation);
    }
    formData.append("fullName", updatedData.fullName);
    if (updatedData.dob) {
      formData.append("dob", updatedData.dob);
    }
    if (updatedData.profileImage) {
      formData.append("profileImage", updatedData.profileImage);
    }
    if (updatedData.coverImage) {
      formData.append("coverImage", updatedData.coverImage);
    }
    console.log(formData);
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token?.accessToken}`,
      },
      body: formData,
    };

    setLoading(true); // Set loading to true before the request
    try {
      const response = await fetch(`${path}user/profile/update`, requestOptions);

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update profile");
      }

      if (responseData && responseData.data) {
        setProfileData(responseData.data);
        toast.success("Profile updated successfully!");
      } else {
        console.error("Unexpected response structure:", responseData);
        toast.error("Profile not updated ");
      }
      await getProfileData();
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setLoading(false);
    }
  }
  // Get Followers data
  async function getFollowersData(userName) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/followers?username=${userName}`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch followers data");
      }
      const data = await response.json();
      setFollowersData(data.data);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  }
  // Get Followers data
  async function getFollowingData(userName) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };

      // Make the API call

      const response = await fetch(`${path}people/following?username=${userName}`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch following data");
      }
      const data = await response.json();
      setFollowingData(data.data); // Store the fetched data in state

      return data;
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  }
  // Like Post
  async function likePost(likes) {
    const storedUser = localStorage.getItem("userName");
    if (storedUser === "Guest") {
      console.error("Guest users cannot like post.");
      return; 
    }else{
      let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(likes),
      };
      // Make the API call
      const response = await fetch(`${path}posts/like`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Like");
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    }
    }
  }
  // DisLike Post
  async function disLikePost(likes) {
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(likes),
      };
      // Make the API call
      const response = await fetch(`${path}posts/like`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Like");
      } else {
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    }
  }
  // Check Username Availability
  async function checkUsernameAvailability(username) {
    let token = getToken();
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      console.log("token", token);
      const response = await fetch(
        `${path}user/profile/username-check?username=${username}`,
        requestOptions
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to check username availability");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error checking username availability:", error);
    }
  }
  // Block-user
  const blockUser = async (username) => {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      const response = await fetch(`${path}people/block/block-user/${username}`, requestOptions);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to block user");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      console.log("User blocked successfully:", data);
      return data;
    } catch (error) {
      console.error("Error blocking user:", error);
    } finally {
      setLoading(false);
    }
  };
  // unblock user
  const unblockUser = async (username) => {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      const response = await fetch(`${path}people/block/unblock-user/${username}`, requestOptions);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to unblock user");
      }
      console.log("User unblocked successfully");
    } catch (error) {
      console.error("Error unblocking user:", error);
    } finally {
      setLoading(false);
    }
  };

  // Mark Post as Not Interested
  async function markPostAsNotInterested(postId) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify({ postId }), // Send the postId in the request body
      };

      // Make the API call
      const response = await fetch(`${path}posts/notinterested/${postId}`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to mark post as not interested");
      } else {
        showToast(data.message);
        // Check if postData is an array before filtering
        setPostData((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter((post) => post.id !== postId);
          }
          return prevData;
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Add the removeFollower
  async function removeFollower(username) {
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ username }),
      };

      // Make the API call
      const response = await fetch(`${path}people/remove-follower/${username}`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to remove follower");
      } else {
        setFollowersData((prevFollowers) => {
          if (Array.isArray(prevFollowers)) {
            return prevFollowers.filter((follower) => follower.username !== username);
          }
          return prevFollowers;
        });
      }
    } catch (error) {
      console.error("Error removing follower:", error.message);
      // showErrorToast(error.message);
    }
  }

  function clearToken() {
    localStorage.removeItem("accessToken");
  }
  // Update Email
  async function updateEmail(newEmail) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}user/profile/update-email/${newEmail}`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      console.log(data, "update email");
      if (!response.ok) {
        throw new Error(data.message || "Failed to update email");
      } else {
        showToast(data.message);
        clearToken();
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
      // showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Get professionlDetails by username
  async function getProfessionalByUsernameDetails(nameUser) {
    let token = getToken();
    // setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/professional-details/get-professional-details-by-username/${nameUser}`,
        requestOptions
      );
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Professional Details");
      } else {
        // setLoading(false);
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      // setLoading(false);
    }
  }
  // Get educationDetails by username
  async function getEducationByUsernameDetails(nameUser) {
    let token = getToken();
    // setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}user/profile/education/get-education-by-username/${nameUser}`,
        requestOptions
      );
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Professional Details");
      } else {
        // setLoading(false);
      }
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      // setLoading(false);
    }
  }
  // Add Report User
  async function reportUser(reportData) {
    let token = getToken();
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(reportData),
      };
      // Make the API call
      const response = await fetch(`${path}people/report`, requestOptions);
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to report user");
      } else {
        showToast(data.message);
        console.log(data.message, "mmnbnbn");
      }
    } catch (error) {
      console.error("Error:", error.message);
      // showErrorToast(error.message);
    } finally {
      setLoading(false);
    }
  }

  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getProfessionalDetails();
      getEducationDetails();
      getProfileData();
      setUsername(localStorage.getItem("userName"));
    }
  }, [username]);

  return (
    <ProfileContext.Provider
      value={{
        getFollowersData,
        getProfessionalDetails,
        searchUniversity,
        getEducationDetails,
        getProfileData,
        getFollowingData,
        disLikePost,
        addEducation,
        educationDtls,
        getEducationByUsernameDetails,
        getProfessionalByUsernameDetails,
        getOtherUserProfileData,
        markPostAsNotInterested,
        likePost,
        setEducationDtls,
        deleteEducation,
        searchUniversityData,
        updateEducation,
        addProfessional,
        professionalDtls,
        setProfessionalDtls,
        deleteProfessionalDetails,
        editProfessionalDetails,
        addProfile,
        editProfile,
        setProfileData,
        followersData,
        followingData,
        profileData,
        updateEmail,
        blockUser,
        unblockUser,
        checkUsernameAvailability,
        removeFollower,
        reportUser,
      }}>
      {loading && <Loader />} {/* Display loader when loading is true */}
      {children}
    </ProfileContext.Provider>
  );
};

export const UseProfileContext = () => {
  return useContext(ProfileContext);
};
