import React, { useEffect, useState, useRef } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { useParams } from "react-router-dom";
import PDFdemmy from "../../assets/images/png/pdficon.png";
import { Helmet } from "react-helmet-async";

export default function ShowPdf() {
  const [fileType, setfileType] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const { getUserPostById } = UseCreatePostData();
  const pdfurlName = useParams();
  let id = pdfurlName.id.includes(".pdf")
    ? pdfurlName.id.split("=")[1]
    : pdfurlName.id;
  let pdfName = pdfurlName.id.split("=")[0].split("_").join(" ");
  const [zoomLevel, setZoomLevel] = useState(1.0); // Default to 100%
  const [totalPages, setTotalPages] = useState(0);
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const toolRef = useRef(null);
  const viewerRef = useRef(null);

  let side = document.querySelector(".rpv-core__minimal-button--selected");
  let bottom = document.querySelector(".rpv-toolbar");
  let themebtn = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-theme-switch"]'
  );
  let upload = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-open"]'
  );
  let download = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-get-file"]'
  );
  let print = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-print"]'
  );

  document.addEventListener("keydown", (event) => {
    if (event.key === "ArrowDown") {
      let downBtn = document.querySelector('button[aria-label="Next page"]');
      if (downBtn) {
        downBtn.click();
      }
    } else if (event.key === "ArrowUp") {
      let upBtn = document.querySelector('button[aria-label="Previous page"]');
      if (upBtn) {
        upBtn.click();
      }
    }
  });

  useEffect(() => {
    if (side && bottom) {
      const existingSvg = side.querySelector("svg");
      if (existingSvg) {
        existingSvg.remove();
      }
      const svgElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      svgElement.setAttribute("height", "19");
      svgElement.setAttribute("width", "19");
      svgElement.setAttribute("viewBox", "0 0 24 24");
      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      pathElement.setAttribute(
        "d",
        "M20.47 22c1.141 0 2.015-.314 2.62-.942.607-.628.91-1.533.91-2.715V5.657c0-1.182-.303-2.087-.91-2.715C22.486 2.314 21.612 2 20.47 2H3.53c-1.141 0-2.015.312-2.62.936C.302 3.56 0 4.467 0 5.656v12.687c0 1.182.303 2.087.91 2.715.605.628 1.479.942 2.62.942h16.94zM8.538 19.352H3.796c-.411 0-.723-.107-.936-.322-.212-.214-.319-.55-.319-1.008V5.978c0-.458.107-.794.32-1.008.212-.215.524-.322.935-.322h4.742v14.704zm11.655 0h-9.315V4.648h9.315c.411 0 .725.107.941.322.216.214.325.55.325 1.008v12.044c0 .458-.109.794-.325 1.008-.216.215-.53.322-.94.322zM6.37 9.335a.566.566 0 00.42-.155c.102-.103.154-.247.154-.432V7.075c0-.406-.192-.61-.574-.61H4.72c-.39 0-.585.204-.585.61v1.673c0 .391.195.587.585.587H6.37zm0 4.089a.566.566 0 00.42-.155c.102-.104.154-.248.154-.432v-1.685c0-.192-.052-.34-.154-.443a.566.566 0 00-.42-.155H4.72c-.191 0-.336.052-.436.155-.099.104-.149.251-.149.443v1.685c0 .184.052.328.155.432.102.103.246.155.43.155H6.37zm0 4.088a.566.566 0 00.42-.155c.102-.103.154-.247.154-.432v-1.684c0-.392-.192-.587-.574-.587H4.72c-.39 0-.585.195-.585.587v1.684c0 .392.195.587.585.587H6.37z"
      );
      pathElement.setAttribute("fill", "#fff");
      svgElement.appendChild(pathElement);
      side.appendChild(svgElement);
      bottom.appendChild(side);
      if (window.innerWidth >= 640) {
        side.click();
      }
    }
    if (themebtn) {
      themebtn.style.display = "none";
    }
    if (upload) {
      upload.style.display = "none";
    }
    if (download) {
      download.style.display = "none";
    }
    if (print) {
      print.style.display = "none";
    }
  }, [side, bottom, print, upload, download]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  async function getpostData() {
    let data = await getUserPostById(id);
    setPdfData(data);
  }
  useEffect(() => {
    getpostData();
    if (id) {
      document.body.classList.add("hide_scroll");
    }
    if (window.innerWidth < 640) {
      setZoomLevel(0.5); // Set to 100% for mobile screens
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(pdfData).length !== 0 && pdfData?.awsUrl.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
      };
      fetchFileFromUrl(pdfData?.awsUrl[0]);
    }
  }, [pdfData]);
  const handleZoomChange = (props) => {
    const scale = props.scale || 1;
    setZoomLevel(Math.round(scale * 100));
  };

  const handleDocumentLoad = (props) => {
    setTotalPages(props.doc.numPages);
    setZoomLevel(1);
  };
  return (
    <>
      <Helmet>
        <title>Question Papers for All Subjects and Exams | Wised.in</title>
        <meta
          name="description"
          content="Government Private Engineering Universities, State & Central Exams, Other exams"
        />

        <meta property="og:url" content="https://Wised.in/question-papers" />
      </Helmet>
      <div className="flex flex-col min-h-screen pdf_div">
        <div className="flex-grow">
          {Object.keys(pdfData).length !== 0 && fileType === "pdf" ? (
            <div style={{ position: "relative" }}>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js`}
              >
                <div className="w-full h-[100vh]">
                  <Viewer
                    ref={viewerRef}
                    fileUrl={pdfData?.awsUrl[0]}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={zoomLevel} // Sets the default zoom level to 100%
                    onDocumentLoad={handleDocumentLoad}
                    onZoom={handleZoomChange}
                    onError={(error) =>
                      console.error("PDF Viewer Error:", error)
                    }
                  />
                </div>
              </Worker>
            </div>
          ) : Object.keys(pdfData).length !== 0 && fileType !== "pdf" ? (
            <div className="w-full min-h-screen flex items-center justify-center pdf_div">
              <img
               height={700}
               width={700}
               loading="lazy"
                className="w-full object-contain max-h-screen"
                src={pdfData?.awsUrl[0]}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}