


import React, { useState, useEffect } from "react";
import Book_icon from "../../assets/images/png/Book_icon.png";

const Genres = ({ data, index }) => {
  const [, setGenresData] = useState(true);
  const [imageUrl, setImageUrl] = useState("");  
  const fileName = data.name || data;

useEffect(() => {
  if (!fileName) return;

  fetch(`https://api.wised.in/api/v1/auth/getImage?imageName=${fileName}`)
    .then((response) => response) 
    .then((res) => setImageUrl(res.url)) 
    .catch((err) => console.error("Error fetching image:", err));
}, [fileName]);



  useEffect(() => {

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 644) {
        if (index >= 3) {
          setGenresData(false);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [data, index]);

  return (
    <>
      <div className="relative rounded-xl overflow-hidden h-[200px] sm:h-[300px] xl:h-[400px]">
        {/* Dynamically fetched image */}
        {imageUrl ? (
          <img
            height={300}
            loading="lazy"
            width={300}
            src={imageUrl}
            alt="Genre"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="bg-gray-300 animate-pulse w-full h-full"></div>
        )}
        <div className="lg:py-5 sm:py-2 py-1 absolute bg-[rgba(255, 255, 255, 0.4)] w-full bottom-0 backdrop-blur-[5.63793px] text-center bg-[#c8c6c666]">
          <p className="text-white font-semibold lg:text-xl px-3 sm:text-base text-xxs pb-3">
            {data.name}
          </p>
          <div>
            <img
              src={Book_icon}
              alt="Book Icon"
              className="pb-2 mx-auto lg:w-[36px] sm:w-[21px] w-[10px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Genres;



