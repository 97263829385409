/* eslint-disable react-hooks/exhaustive-deps */


import { useEffect, useState } from "react";
import { SearchIcon, CheckIcon } from "../common/Icons";
import { UseInterestContext } from "../ContextApis/SettingsContext";

const UserInterestPopup = ({ setSelectedInterest }) => {
  const { searchInterestData, searchInterest, allInterest, getAllInterestData } =
    UseInterestContext();
  const [suggestedInterests, setSuggestedInterests] = useState([]);
  const [checkedAddedInterests, setCheckedAddedInterests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [interstDropdown, setInterstDropdown] = useState(false);

  const handleToggleAddedInterest = (interest) => {
    if (!checkedAddedInterests.includes(interest)) {
      setCheckedAddedInterests((prev) => [...prev, interest]);
      setSelectedInterest((prev) => [...prev, interest]);
    } else {
      setCheckedAddedInterests((prev) => prev.filter((item) => item !== interest));
      setSelectedInterest((prev) => prev.filter((item) => item !== interest));
    }
  };

  const handleSearchItemClick = (item) => {
    if (!suggestedInterests.includes(item)) {
      setSuggestedInterests((prev) => [...prev, item]);
    }
    handleToggleAddedInterest(item);
    setInterstDropdown(false);
  };

  let token = null;
  useEffect(() => {
    let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
    let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
    token = localTokenStore || sessionTokenStore;
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0) {
      searchInterestData(searchTerm, token);
      setInterstDropdown(true);
    } else {
      setInterstDropdown(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    getAllInterestData().then(() => {
      const defaultSuggestions = [...allInterest].slice(0, 10); 
      setSuggestedInterests(defaultSuggestions);
    });
  }, []);

  return (
    <>
      <div className=" flex flex-col sm:px-5 md:px-12">
        <h1 className="text-center text-base font-medium mt-3">What interests you?</h1>
        <h2 className="text-center text-base font-medium mt-3 max-sm:text-sm text-grey">
          Choose 5 or more
        </h2>
        <div className="flex gap-3 justify-center items-center relative">
          <span className="pt-2 search_icon">
            <SearchIcon />
          </span>
          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mt-3 outline-none w-[112px] max-sm:text-xs"
            placeholder=" |  Looking for...."
          />
          {interstDropdown && (
            <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full py-2 z-[1] rounded-xl dropdown max-h-[250px] overflow-y-auto">
              {searchInterest
                .filter((item) => !checkedAddedInterests.includes(item))
                .map((item, index) => (
                  <p
                    onClick={() => handleSearchItemClick(item)}
                    key={index}
                    className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 px-4 rounded my-1"
                  >
                    {item}
                  </p>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-5 mt-3 px-4 h_max overflow-y-auto sm:px-5 md:px-11 sm:mx-2">
        {suggestedInterests.length > 0 ? (
          suggestedInterests.map((interest) => (
            <span
              key={interest}
              className="py-[6px] px-[18px] border border-primary rounded-[5px] whitespace-nowrap flex items-center gap-1 cursor-pointer text-primary h-[38px]"
              onClick={() => handleToggleAddedInterest(interest)}
            >
              {checkedAddedInterests.includes(interest) && <CheckIcon />}
              {interest}
            </span>
          ))
        ) : (
          <p className="text-gray-500">No results found</p>
        )}
      </div>
    </>
  );
};

export default UserInterestPopup;
