// /* eslint-disable no-multi-str */
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({ message, setContent }) => {
  const [value, setValue] = useState(message);

  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setContent(editor.getHTML());
  };


  return (
    <div className="texteditor-container sm:h-[340px] xl:h-[515px] overflow-x-hidden">
      <ReactQuill
        value={value}
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Write something..."
      />
    </div>
  );
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: true,
      onPaste: function (event) {
        event.preventDefault();
  
        const clipboardData = event.clipboardData || window.Clipboard;
        const pastedHtml = clipboardData.getData("text/html");
        const pastedText = clipboardData.getData("text/plain");
  
        if (pastedHtml) {
          let processedHtml = pastedHtml;
          processedHtml = processedHtml.replace(/(<h[1-6][^>]*>[^<]+<\/h[1-6]>)/g, "$1<br>");
          processedHtml = processedHtml.replace(/(<p>[^<]+<\/p>)/g, "<br> <br>");
          const range = this.quill.getSelection();
          this.quill.clipboard.dangerouslyPasteHTML(range.index, processedHtml);
        } else {
          const processedText = pastedText.replace(/\n/g, "<br>","<br>");
          const range = this.quill.getSelection();
          this.quill.clipboard.dangerouslyPasteHTML(range.index, processedText);
        }
      },
    },
};

const formats = ["bold", "italic", "underline", "link", "image"];

export default Texteditor;



