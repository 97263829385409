import React from "react";

function Continue({ btnname, onclick }) {
  return (
    <div>
      <button
       
        className={`text-sm font-normal w-[132px] h-[40px] sm:h-[50px] md:h-[60px] md:px[32px] ms:py-[18px] md:py-[18px] border border-1 border-[#BEC1C3] px-6 sm:px-[30px] sm:py-[8px] rounded-[100px] sm:text-sm ${
          btnname == "Save" ? "w-[83px] sm:w-[330px] lg:w-[117px]" : ""
        } ${
          btnname == "Cancel" || btnname == "Save"
            ? " bg-[#4D8AFF] text-white "
            : " text-black bg-white"
        }`}>
        {btnname}
      </button>
    </div>
  );
}

export default Continue;
