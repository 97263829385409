import React, { useEffect } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { SpotlightData } from "../../Store/HomeData";
import { LeftIcon } from "../../components/common/Icons";
import { Link, useParams } from "react-router-dom";
import { UseHomeContext } from "../ContextApis/HomeContext";
const PopularGenres = () => {
  const { category } = useParams();
  const { GetPopulargenres, populargenres } = UseHomeContext();
  useEffect(() => {
    GetPopulargenres(category);
  }, [category]);

  console.log(category,"category")

  
  return (
    <>
      <section className="px-6 max-sm:px-4 max-lg:pb-14">
        <div className="lg:pt-[30px] sm:pt-[50px] pt-4 lg:pb-[30px] sm:pb-[50px] pb-4 sm:text-center text-start">
          <div className="lg:pb-2 sm:pb-4 pb-0 flex items-center sm:justify-center justify-start">
            <Link onClick={() => window.history.back()}>
              <LeftIcon />
            </Link>
            <span className=" text-black font-semibold sm:text-lg text-base text-center ">
              Popular Genres
            </span>
          </div>
          <p className=" font-normal text-base sm:ps-0 ps-10">{category.split("_").join(" ")}</p>
        </div>
        <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
          {populargenres.map((item, index) => {
            return (
              <Link key={index}>
                <Spotlight data={item} />
              </Link>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default PopularGenres;
