import React from "react";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllNotifications = ({
  notif,
  handleNotificationClick,
  timeAgo,
  live_dott,
  bansal_sndp,
  goback,
}) => {
  const navigate=useNavigate()
  const sanitizedHtml = DOMPurify.sanitize(notif.message);


  console.log(notif,"skdfskfm,")


  return (
    
    <div
      key={notif.id}
      onClick={() => { 
        handleNotificationClick(notif.id);
        if (notif.redirectUrl) {
          goback();
          // navigate('/redirectUrl')
        } else {
          toast.error("Post has been deleted by user !");
        }
      }}
      className={`notification-item flex justify-between items-start mb-6 w-full ${
        notif.notificationStatus === "UNREAD" ? "bg-light-gray" : ""
      }`}>
      <div className="flex items-start">
        <img
                 className="min-w-[36px] max-w-[36px] h-[36px] sm:min-w-[45px] sm:max-w-[45px] sm:h-[45px] rounded-full"
          src={bansal_sndp}
          alt="Profile"
        />
        <div className="ms-2.5 descri">
          <div className="text-sm sm:text-md" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          <p className="text-grey text-xs sm:text-base mt-0.5">{timeAgo(notif.createdAt)}</p>
        </div>
      </div>
      {notif.notificationStatus === "UNREAD" && (
        <img className="cursor-pointer mt-4 ms-6" src={live_dott} alt="Unread Indicator" />
      )}
    </div>


  );
};

export default AllNotifications;
