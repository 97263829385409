import React, { useRef, useState } from "react";
import welcome_img from "../../assets/images/png/welcome.png";
import Logo from "../../assets/images/svg/Logo.svg";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import Signup from "../popup/Signup";
import Signin from "../popup/Signin";
import Verifyemail from "../popup/Verifyemail";
import PersonliseComman from "../LoginSignup/PersonliseComman";
import { UseAuth } from "../ContextApis/AuthContext";

function Welcome() {
  const [signInPopup, setSignInPopup] = useState(false);
  const [signUpPopup, setSignUpPopup] = useState(false);
  const [verifyOtpPopup, setVerifyOtpPopup] = useState(false);
  const [personlisePopup, setPersonlisePopup] = useState(false);
  const [email, setEmail] = useState("");
  const popupRef = useRef();
  const { continueAsGuest } = UseAuth();

  if (signInPopup || signUpPopup || verifyOtpPopup || personlisePopup) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  return (
    <div className=" bg-white h-screen flex w-full justify-evenly gap-12 lg:gap-0 lg:justify-around items-center flex-col lg:flex-row px-3 py-8">
      <div className="mb-9 sm:hidden">
        <Link to="/">
          <img
            className="w-[91px] relative max-sm:top-[30px]"
            src={Logo}
            alt="Logo"
            width={91}
            height={91}
            loading="lazy"
          />
        </Link>
      </div>

      <div className="w-full sm:w-[75%] lg:w-6/12 xl:w-5/12">
        <img
          className="max-sm:max-w-[300px] max-lg:max-w-[360px] max-lg:mx-auto max-sm:pb-14"
          src={welcome_img}
          width={600}
          height={600}
          loading="lazy"
          srcSet={`${welcome_img} 300w, ${welcome_img} 768w, ${welcome_img} 1200w`}
          sizes="(max-width: 640px) 100vw, (max-width: 1024px) 75vw, 50vw"
          alt="welcome_img"
        />
      </div>

      <div className="  flex flex-col gap-6 items-center w-full  lg:w-4/12  xl:w-3/12">
        <div className="mb-9 hidden sm:flex">
          <Link to="/">
            <img width={300} height={300} loading="lazy" className="w-[200px] xl:w-[330px]" src={Logo} alt="Logo" />
          </Link>
        </div>
        <div
          onClick={() => setSignInPopup(true)}
          className="w-full text-center"
        >
          <Button btnname="Sign In" />
        </div>
        <div
          onClick={() => setSignUpPopup(true)}
          className="w-full text-center"
        >
          <Button btnname="Sign Up" />
        </div>

        <Link
          onClick={continueAsGuest}
          className="text-base cursor-pointer font-semibold leading-4 text-primary pb-5"
        >
          Continue as guest
        </Link>
      </div>
      {(signInPopup || signUpPopup || verifyOtpPopup || personlisePopup) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {signInPopup && (
        <div>
          <Signin
            setSignInPopup={setSignInPopup}
            setSignUpPopup={setSignUpPopup}
            signInRef={popupRef}
          />
        </div>
      )}
      {signUpPopup && (
        <div>
          <Signup
            email={email}
            setEmail={setEmail}
            setSignUpPopup={setSignUpPopup}
            setSignInPopup={setSignInPopup}
            setVerifyOtpPopup={setVerifyOtpPopup}
            signInRef={popupRef}
          />
        </div>
      )}
      {verifyOtpPopup && (
        <div>
          <Verifyemail
            email={email}
            setSignUpPopup={setSignUpPopup}
            setVerifyOtpPopup={setVerifyOtpPopup}
            signInRef={popupRef}
            setPersonlisePopup={setPersonlisePopup}
          />
        </div>
      )}
      {personlisePopup && (
        <div>
          <PersonliseComman
            personlisePopup={personlisePopup}
            setPersonlisePopup={setPersonlisePopup}
          />
        </div>
      )}
    </div>
  );
}

export default Welcome;
