import { useEffect, useState } from "react";
import { SearchIcon } from "../common/Icons";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import userImg from "../../assets/images/png/user-img2.png";
import { UseNavDataContext } from "../ContextApis/NavDataContext";

const RecommondedPeople = ({ folllowCount, setFollowCount }) => {
  const [search, setSearch] = useState("");
  const { getRecommededPeople, recomendedPeople } = UseCreatePostData();
  const { followUser, unFollowUser } = UseTopCreatorsContext();
    const { global, getGlobal } = UseNavDataContext();


    let usersData =
     global?.users && global?.users.length > 4
      ? global?.users.slice(0, 4)
      : global.users || [];

    console.log(usersData,"glonald")

    useEffect(() => {
        if (search.length > 0) {
          getGlobal(search);
        } else {
          getGlobal("");
        }
      }, [search]);
  async function getfollowUser(data) {
    if (!folllowCount.includes(data)) {
      setFollowCount([...folllowCount, data]);
    } else {
      let filterdata = folllowCount.filter((item) => item !== data);
      setFollowCount(filterdata);
    }
  }
  let searchPeople = recomendedPeople.filter(
    (items) =>
      items && items.fullName && items.fullName.toLowerCase().includes(search.toLowerCase())
  );
  function following(username) {
    followUser(username);
  }

  function unFollow(username) {
    unFollowUser(username);
  }
  useEffect(() => {
    getRecommededPeople();
  }, []);

  console.log(search,"search")
  return (
    <div className="md:px-2">
      <div>
        <h1 className="text-center text-base font-medium mt-3">Recommended people</h1>
        <h2 className="text-center text-sm md:text-base font-medium text-grey mt-3">
          Choose 5 or more
        </h2>
        <div className="flex gap-3 justify-center items-center mt-3">
          <span className="search_icon">
            <SearchIcon />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="outline-none w-[112px] max-sm:text-xs"
            placeholder=" |  Looking for...."></input>
        </div>
      </div>
      <div className="mt-4 xl:mt-8">
        <div className="items-center justify-between h_max overflow-y-auto hide-scrollbar px-4 sm:px-5 md:px-12">
          {Array.isArray(recomendedPeople) &&
            usersData.map((val, index) => {
              return (
                <div className="flex items-center justify-between mt-4" key={index}>
                  <div className="flex items-center gap-2">
                    <img
                      className="w-[42px] h-[42px] sm:w-[51px] sm:h-[51px] rounded-full"
                      src={val.profileImageUrl || userImg}
                      alt="dp"
                    />
                    <div>
                      <p className="mb-0 font-medium text-base sm:text-md">{val.fullName}</p>
                      <p className="mb-0 text-sm sm:text-base"></p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      if (!folllowCount.includes(val.id)) {
                        following(val?.userName);
                      } else {
                        unFollow(val?.userName);
                      }
                      getfollowUser(val?.id);
                    }}
                    className={`rounded-[100px] h-[28px] md:h-[36px] max-sm:text-[14px] px-3 ${
                      !folllowCount.includes(val.id)
                        ? "bg-primary text-white"
                        : "text-gray border border-solid border-gray"
                    }`}>
                    {!folllowCount.includes(val.id) ? "Follow" : "Following"}
                  </button>
                </div>
              );
            })}
        </div>
        <div className="items-center justify-between h_max overflow-y-auto hide-scrollbar px-4 sm:px-5 md:px-12">
          {Array.isArray(recomendedPeople) &&
            searchPeople.map((val, index) => {
              return (
                <div className="flex items-center justify-between mt-4" key={index}>
                  <div className="flex items-center gap-2">
                    <img
                      className="w-[42px] h-[42px] sm:w-[51px] sm:h-[51px] rounded-full"
                      src={val.profileImageUrl || userImg}
                      alt="dp"
                    />
                    <div>
                      <p className="mb-0 font-medium text-base sm:text-md">{val.fullName}</p>
                      <p className="mb-0 text-sm sm:text-base"></p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      if (!folllowCount.includes(val.id)) {
                        following(val?.userName);
                      } else {
                        unFollow(val?.userName);
                      }
                      getfollowUser(val?.id);
                    }}
                    className={`rounded-[100px] h-[28px] md:h-[36px] max-sm:text-[14px] px-3 ${
                      !folllowCount.includes(val.id)
                        ? "bg-primary text-white"
                        : "text-gray border border-solid border-gray"
                    }`}>
                    {!folllowCount.includes(val.id) ? "Follow" : "Following"}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RecommondedPeople;
