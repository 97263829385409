import { Link, NavLink, Outlet } from "react-router-dom";
import { BackArrowIcon, RightArrowIcon } from "../common/Icons";
import Logout from "./Logout";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const SideBar = () => {
  const [logoutPopup, setLogoutPopup] = useState(false);

  return (
    <>
      <Helmet>
        <title>Manage Your Account Settings at Wised.in</title>
        <meta
          name="description"
          content="Customize your Wised.in account settings, including password changes, email preferences, and security options. Take control of your user experience with easy-to-navigate settings
"
        />
       
        <meta property="og:url" content="https://wised.in/setting" />
      
      </Helmet>
      {logoutPopup && <Logout setLogoutPopup={setLogoutPopup} />}
      <div className="flex items-start relative">
        <div className="flex h-[calc(100vh-60px)] lg:h-[calc(100vh-75px)] flex-col sm:gap-8 min-w-full w-full sm:min-w-[232px] md:min-w-[300px] xl:min-w-[410px] sm:w-[232px] md:w-[300px] xl:w-[410px] border-r-2 border-[#e0e1e1a0] sm:pt-10 lg:pt-[88px] px-6 md:px-5 xl:ps-7 max-sm:absolute max-sm:left-0 sm:sticky sm:top-[60px] lg:top-[75px] z-10 bg-white items-start">
          <div className="flex items-center sm:hidden mb-7 mt-4 gap-4">
            <Link to="/home">
              <BackArrowIcon />
            </Link>

            <h2 className="font-medium text-base text-black capitalize">
              settings
            </h2>
          </div>

          <div className="flex items-start flex-col gap-[26px] sm:gap-8 max-sm:w-full">
            <NavLink
              to="/settings/accounts-settings"
              className="font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between sidebar_links sm:text-[#636364]  max-sm:w-full"
            >
              Accounts
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </NavLink>

            <NavLink
              to="/settings/privacy-security"
              className="font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between sidebar_links sm:text-[#636364]  max-sm:w-full"
            >
              Privacy and security
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </NavLink>

            <NavLink
              to="/settings/notifications-settings"
              className="font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between sidebar_links sm:text-[#636364]  max-sm:w-full"
            >
              Notifications
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </NavLink>

            <NavLink
              to="/settings/helps"
              className="font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between sidebar_links sm:text-[#636364]  max-sm:w-full"
            >
              Help
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </NavLink>
            <button
              onClick={() => setLogoutPopup(true)}
              className="font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between sidebar_links sm:text-[#636364]  max-sm:w-full"
            >
              Logout
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default SideBar;
